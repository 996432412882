import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { History } from 'history';
import { IInstagramStore } from '../../shared/interfaces';
import Footer from '../Footer';
import Header from '../Header';
import Loader from '../Loader';
import NewsFeed from '../NewsFeed';
import classes from './styles.module.scss';

interface IProps {
	instagramStore: IInstagramStore;
	history: History;
}

interface IState {
	feed: Array<any>;
}

@inject('instagramStore')
@observer
export default class News extends Component<IProps, IState> {
	componentDidMount(): void {
		window.scrollTo(0, 0)
		const { instagramStore: { fetchMyFeed } } = this.props;
		fetchMyFeed();
	}

	render() {
		const { instagramStore: { myFeed, isFetching } } = this.props;

		return (
			<div className={classes.Contact}>
        <Header
					history={this.props.history}/>
					{
						isFetching ?
							<div className={classes['Contact-wrapper']}>
								<Loader />
							</div> :
							<NewsFeed
								feed={myFeed} />
					}
				<Footer />
			</div>
		);
	}
}
