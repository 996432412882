import React, { Component, createRef } from 'react';
import DatePicker from '../DatePicker';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import {DebounceInput} from 'react-debounce-input';

import moment from 'moment';

interface IProps {
  value: string;
  name: string;
  type?: 'text' | 'number' | 'email' | 'currency' | 'date' | 'tel' | 'select';
  size?: 'small' | 'medium' | 'large';
  error?: boolean;
  changed: (value: string) => void;
  placeholderTextId: string;
  submitted?: () => void;
  tabIndex?: number;
  capitalize?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  styles?: object;
  required?: boolean;
  dynamic?: boolean;
  theme?: 'underline';
  debounceTimeout?: number;
}

class Input extends Component<IProps> {
  private textInput = createRef<HTMLInputElement>();

  state = {
    focused: false
  };

  handleInputFocus = () => {
    if (this.textInput.current) {
      this.textInput.current.focus();
      this.setState({
        focused: true
      });
    }
  };

  handleInputKeyPress = e => {
    if (e.keyCode === 13 && this.props.submitted) {
      this.props.submitted();
    }
  };

  handleChangeDate = date => {
    const d = moment(date,'DD-MM-YYYY').format('DD-MM-YYYY');
    this.props.changed(d)
  };

  handleChangeValue = e => {
    const value = e.target.value;

    this.props.changed(value);
  };

  render() {
    const {
      value,
      type,
      styles,
      placeholderTextId,
      size,
      error,
      tabIndex,
      capitalize,
      uppercase,
      lowercase,
      required,
      name,
      dynamic,
      theme,
      debounceTimeout
    } = this.props;
    const { focused } = this.state;

    return (
      <div
        className={[
          classes.Input,
          size ? classes[`Input--${size}`] : '',
          theme ? classes[`Input--${theme}`] : '',
          error ? classes[`Input--error`] : '',
          focused || value.length ? classes['is-focused'] : '',
          dynamic ? classes['Input--dynamic'] : '',
        ].join(' ')}
        onClick={this.handleInputFocus}
      >
        <FormattedMessage id={placeholderTextId} defaultMessage={''}>
          {(placeholder: any) => (
            type === 'date' ?
              <DatePicker
                placeholder={`${placeholder}${required ? '*' : ''}`}
                value={value}
                changed={this.handleChangeDate} /> :
              <DebounceInput
                debounceTimeout={debounceTimeout || 0}
                name={name}
                type={type === 'currency' ? 'text' : type}
                autoComplete={'off'}
                style={styles}
                placeholder={`${placeholder}${required ? '*' : ''}`}
                className={[
                  classes['Input-control'],
                  capitalize ? classes['Input-control--capitalize'] : '',
                  uppercase ? classes['Input-control--uppercase'] : '',
                  lowercase ? classes['Input-control--lowercase'] : ''
                ].join(' ')}
                onKeyDown={this.handleInputKeyPress}
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false })}
                onChange={e => this.handleChangeValue(e)}
                value={value}
                tabIndex={tabIndex}
              />
            )}
        </FormattedMessage>
      </div>
    );
  }
}

export default Input;
