import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './styles.module.scss';

import Label from '../Label';

interface IProps {
  items: Array<{
    label: object;
    key: string;
  }>
	clicked: (value: string) => void;
	styles?: object;
	disabled?: boolean;
	value: string;
}

class Radio extends Component<IProps> {

  render() {
    const { items, clicked, styles, disabled, value } = this.props;

    const buttonsTpl = items.map((item: { label: object, key: string }, index: number) => {

      const buttonClasses = [
        classes['Radio-button'],
        value === item.key ? classes[`is-active`] : '',
      ].join(' ');

      return (
        <button
          key={index}
          type={'button'}
          disabled={disabled}
          style={styles}
          onClick={() => clicked(item.key)}
          className={buttonClasses}>
          { item.label }
        </button>
      )
    });

    return (
      <div className={classes.Radio}>
        <Label light={true}>
          <FormattedMessage id={'label.unit'} />
        </Label>
        <div className={classes['Radio-buttons']}>
          { buttonsTpl }
        </div>
      </div>
    )
  }
}

export default Radio;
