import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { History } from 'history';
import * as routes from '../../shared/routes';
import { NavLink } from 'react-router-dom';
import Button from '../Button';
import Input from '../Input';
import OverlayNav from '../OverlayNav';
import Logo from '../Logo';
import classes from './styles.module.scss';

interface IProps {
	hideLogo?: boolean;
	history: History;
	hasSearch?: boolean;
	searchChanged?: (str: string) => void;
	searchValue?: string;
	center?: boolean;
	light?: boolean;
	overlay?: boolean;
	wide?: boolean;
}

interface IState {
	showNav: boolean;
}

class Header extends Component<IProps, IState> {
	state = {
		showNav: false
	};

	listener;

	componentDidMount(): void {
		this.listener = this.props.history.listen(this.onRouteChange.bind(this));
	}

	onRouteChange(route) {
		//console.log('change', route.pathname);
		this.setState({
			showNav: false,
		})
	}

	componentWillUnmount(): void {
		this.listener();
	}

	handleToggleNav = () => {
		this.setState({
			showNav: !this.state.showNav
		})
	};

	handleCloseNav = () => {
		this.setState({
			showNav: false
		})
	};

	render(){
		const { showNav } = this.state;
		const { hideLogo, hasSearch, searchChanged, searchValue, center, light, overlay, wide } = this.props;

		return (
			<header className={[
				classes.Header,
				center ? classes['Header--center'] : '',
				overlay ? classes['Header--overlay'] : '',
				wide ? classes['Header--wide'] : ''
			].join(' ')}>
				<div className={classes['Header-content']}>
					{
						!hideLogo &&
							<NavLink to={routes.HOME} className={classes['Header-logo']}>
								<Logo light={light} />
							</NavLink>
				}
					{
						hasSearch && searchChanged &&
						<div className={classes['Header-search']}>
							<Input
								debounceTimeout={500}
								styles={{
									maxWidth: '25rem'
								}}
								theme={'underline'}
								value={searchValue || ''}
								name={'search'}
								changed={searchChanged}
								placeholderTextId={'placeholder.search'} />
						</div>
					}
					<div className={classes['Header-menu']}>
						<Button
							type={'link'}
							active={true}
							theme={light && !showNav ? 'light' : 'dark'}
							clicked={this.handleToggleNav}>
							{
								showNav ? <FormattedMessage id={'button.close'} /> : <FormattedMessage id={'button.menu'} />
							}
						</Button>
					</div>
				</div>
				<OverlayNav
					closeClicked={this.handleCloseNav}
					show={showNav} />
			</header>
		)
	}
}

export default Header;
