export const HOME = '/';
export const WOMEN = '/women/:group?';
export const TALENT = '/talent';
export const SHOP = 'https://shop.fabbricamilano.com';
export const MODEL = '/model/:id';
export const SCOUTING = '/scouting';
export const CONTACT = '/contact';
export const NEWS = '/news';
export const PRIVACY_POLICY = '/privacy-policy';
export const THANK_YOU = '/thank-you';
