import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { IFeed } from '../../shared/interfaces';
import InstagramCard from '../InstagramCard';
import classes from './styles.module.scss';
import constants from '../../shared/constants';

interface IProps {
	styles?: object;
	feed: Array<any>;
}

class NewsFeed extends Component<IProps> {
	render () {
		const { feed } = this.props;

		const feedTpl = feed ? feed.map((feed: IFeed, index: number) => {
			const padded = constants.NEWS_PADDED_INDEXES.indexOf(index) > -1;

			return (
				<InstagramCard
					padded={padded}
					key={index}
					{...feed} />
			)
		}) : null;

		return (
			<div className={classes.NewsFeed} style={this.props.styles}>
				<h1 className={classes['NewsFeed-title']}>
					<FormattedHTMLMessage id={'news.title'} />
				</h1>
				<div className={classes['NewsFeed-items']}>
					{ feedTpl }
				</div>
			</div>
		);
	}
}

export default NewsFeed;
