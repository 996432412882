import React, {Component, createRef, ReactElement} from 'react';
import { getViewPortSize } from '../../shared/helpers';
import Mosaic from '../Mosaic';
import Picture from '../Picture';
import Loader from '../Loader';
import classes from './styles.module.scss';
import arrowBackIcon from '../../assets/icons/arrow-back.svg';
import arrowNextIcon from '../../assets/icons/arrow-next.svg';
import AliceCarousel from "react-alice-carousel";

const responsive = {
	0: { items: 1 },
};

interface IProps {
  id: number;
  showThumbnails: boolean;
  toggleThumbnails: (reset?: boolean) => void;
  mosaicAlignment?: 'center' | 'normal';
	mosaicSize?: 'large';
  single?: boolean;
	isDesktop: boolean;
	media: {
		portfolio: {
			pictures: Array<string>;
		};
		digitals: {
			pictures: Array<string>;
		};
		instagram: {
			pictures: Array<string>;
			links: Array<string>;
		};
	};
	album: 'portfolio' | 'digitals' | 'instagram';
}

interface IState {
  images: Array<string>;
	links: Array<string>;
  loading: boolean;
  hideGalleryItem: boolean;
}

export default class ModelGallery extends Component<IProps, IState> {
	modelCarousel = createRef<AliceCarousel>();

  state = {
    mosaic: false,
    images: [],
		links: [],
		loading: true,
		hideGalleryItem: false,
  };

  componentDidMount(): void {
  	const { media, album } = this.props;

    this.setState({
      images: media[album].pictures,
			links: album === 'instagram' ? media[album].links : []
    });

    setTimeout(() => {
    	this.setState({
				loading: false
			}, () => {
				document.addEventListener('keydown', this.addKeyEvents);
			})
		}, 2000);
  }

  componentWillUnmount(): void {
		window.removeEventListener('keydown', this.addKeyEvents);
  }

  addKeyEvents = (event: KeyboardEvent) => {
		if (event.code === 'ArrowLeft') {
			this.goToPreviousSlide()
		}
		if (event.code === 'ArrowRight') {
			this.goToNextSlide()
		}
	};

  resetSlider = () => {
		setTimeout(() => {
			if(this.modelCarousel.current){
				this.modelCarousel.current.slideTo(0);
			}
			this.setState({
				loading: false,
			})
		}, 2000);
  };

  goToSlide = (index: number) => {
  	const { isDesktop } = this.props;

  	this.setState({
			hideGalleryItem: true
		})

		if(this.modelCarousel.current){
			const ix = this.props.single ?  index : Math.round(index / 2);
			const currentIndex = !isDesktop ? index : ix;
			this.modelCarousel.current.slideTo(currentIndex);

			this.props.toggleThumbnails();
		}

		setTimeout(() => {
			this.setState({
				hideGalleryItem: false
			})
		}, 300)

  };

  goToNextSlide = () => {
    if(this.modelCarousel.current){
      this.modelCarousel.current.slideNext();
    }
  };

  goToPreviousSlide = () => {
    if(this.modelCarousel.current){
      this.modelCarousel.current.slidePrev();
    }
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if(this.props.id !== prevProps.id) {
      this.setState({
				images: this.props.media[this.props.album].pictures,
				loading: true,
      }, () => {
        	this.resetSlider();
      });
    }
  }

  getImagesTpl = () => {
  	const links = this.props.album === 'instagram' ? this.props.media[this.props.album].links : [];
  	const images = this.props.media[this.props.album].pictures;

    const sizes = getViewPortSize();

    let imagesTpl: Array<ReactElement> = [];

    if(this.props.single){
      imagesTpl = images.map((img: string, index: number) => {
        return (
          <Picture
						full={true}
            key={index}
						to={links.length > 0 ? links[index] : ''}
            clicked={this.goToNextSlide}
            image={img} />
        )
      })
    } else {

      if(sizes && sizes.width <= 992){
        imagesTpl = images.map((img: string, index: number) => {
          return (
            <Picture
              key={index}
							to={links.length > 0 ? links[index] : ''}
              clicked={this.goToNextSlide}
              image={img} />
          )
        })
      } else {
        imagesTpl.push(
          <Picture
            key={0}
						to={links.length > 0 ? links[0] : ''}
            clicked={this.goToNextSlide}
            image={images[0]} />
        );

        const otherImages = images.slice(1, images.length);

        const leftImages = otherImages.filter((img: string, index: number) => index % 2 === 0);
        const rightImages = otherImages.filter((img: string, index: number) => index % 2 !== 0);

        for(let i = 0; i < leftImages.length; i++){
          imagesTpl.push(
            <div className={classes['ModelGallery-wrapper']} key={i}>
              <Picture
								to={links.length > 0 ? links[i] : ''}
                clicked={this.goToNextSlide}
                image={leftImages[i]} />
              <Picture
								to={links.length > 0 ? links[i] : ''}
                clicked={this.goToNextSlide}
                image={rightImages[i]} />
            </div>
          );
        }
      }
    }

    return imagesTpl;
  };

  render(){
    const { id, mosaicAlignment, showThumbnails, mosaicSize, isDesktop } = this.props;
    const { loading, hideGalleryItem } = this.state;

    return (
      <div className={classes.ModelGallery}>
        {
          !showThumbnails && !loading &&
            <div className={classes['ModelGallery-navigation']}>
              <button
                className={classes['ModelGallery-button']}
                onClick={this.goToPreviousSlide}>
                <img src={arrowBackIcon} alt={'back'} />
              </button>
              <button
                className={[classes['ModelGallery-button'], classes['ModelGallery-button--next']].join(' ')}
                onClick={this.goToNextSlide}>
                <img src={arrowNextIcon} alt={'next'} />
              </button>
            </div>
        }
				{
					loading && <Loader alignment={'center'} size={'large'} />
				}
        <div className={[
          classes['ModalGallery-content'],
          !showThumbnails && !loading ? classes['is-visible'] : '',
					hideGalleryItem ? classes['is-hidden'] : '',
        ].join(' ')}>
					{
						!loading &&
							<AliceCarousel
								swipeDisabled={isDesktop}
								ref={this.modelCarousel}
								autoPlay={false}
								buttonsDisabled={true}
								dotsDisabled={true}
								responsive={responsive}>
								{
									this.getImagesTpl()
								}
							</AliceCarousel>
					}
        </div>
        {
          showThumbnails && !loading &&
            <Mosaic
							size={mosaicSize}
              imageClicked={this.goToSlide}
              alignment={mosaicAlignment}
              images={this.props.media[this.props.album].pictures}
              id={id} />
        }
      </div>
    )
  }
}
