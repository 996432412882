import React, { Component } from 'react';
import { getTimeDiff } from '../../shared/helpers';
import classes from './styles.module.scss';

interface IProps {
	image: string;
	date?: number;
	caption?: string;
	padded?: boolean;
	link: string;
}

class InstagramCard extends Component<IProps> {
	timeDiff = (timestamp: number) => {
		return getTimeDiff(timestamp)
	};

	render () {
		const {
			image,
			date,
			caption,
			link,
			padded,
		} = this.props;

		const backgroundImage = {
			backgroundImage: image ? `url(${image})` : 'none'
		};

		return (
			<div
				className={[
					classes.InstagramCard,
					padded ? classes['InstagramCard--padded'] : '',
				].join(' ')}>
				<a href={link} target={'_blank'}>
					<div className={classes['InstagramCard-image']} style={backgroundImage} />
				</a>
				{
					date &&
						<div className={classes['InstagramCard-time']}>
							{ this.timeDiff(date) }
						</div>
				}
				{
					caption &&
						<div className={classes['InstagramCard-caption']} dangerouslySetInnerHTML={{__html: caption}} />
				}
			</div>
		);
	}
}

export default InstagramCard;
