import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { getViewPortSize } from '../../shared/helpers';
import { IModel, IModelStore } from '../../shared/interfaces';
import WomanCard from '../WomanCard';
import Loader from '../Loader';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';

interface IProps {
	styles?: object;
	modelStore: IModelStore;
	searchKey?: string;
}

@inject('modelStore')
@observer
class WomanList extends Component<IProps> {
	state = {
		listHeight: '20rem'
	};

	componentDidMount(): void {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		const sizes = getViewPortSize();

		if(sizes){
			this.setState({
				listHeight: `calc(${sizes.height}px - 25rem)`
			});
		}
	};

	componentWillUnmount(): void {
		window.removeEventListener("resize", this.updateDimensions);
	}

	render () {
		const { modelStore: { models, isFetching }, styles, searchKey } = this.props;
		const { listHeight } = this.state;

		const modelsTpl = models.length ? models.map((model: IModel, index: number) => {
			return (
				<WomanCard
					key={index}
					id={model.id}
					image={model.cover}
					content={model.firstName + ' ' + model.lastName} />
			)
		}) : null;

		return (
			<div className={classes.WomanList} style={styles}>
				<div
					style={{
						minHeight: listHeight
					}}
					className={classes['WomanList-items']}>
					{
						isFetching ?
							<Loader /> :
							modelsTpl
					}
					{
						models.length === 0 && !isFetching ?
							<FormattedMessage id={'error.noModelsFound'} defaultMessage={''}>
								{
									(text: any) => (
										<span className={classes['WomanList-feedback']}>
												{ text.replace('--key--', searchKey)}
										</span>
									)
								}
							</FormattedMessage> : null
					}
				</div>
			</div>
		);
	}
}

export default WomanList;
