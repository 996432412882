import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { GENDERS } from '../../shared/data';
import { ISelectItem } from '../../shared/interfaces';
import classes from './Select.module.scss';
import SelectItem from './SelectItem/SelectItem';
import arrowDownIcon from '../../assets/icons/arrow-down.svg';

interface IProps {
  options: Array<ISelectItem>;
  name: string;
  placeholder?: object;
  itemClicked: (value: string) => void;
  disabled?: boolean;
  value: string;
  size?: 'small' | 'medium' | 'large';
  placeholderTextId: string;
}

interface IState {
  showList: boolean;
  focused: boolean;
}

class Select extends Component<IProps, IState> {
  select;
  selectInput;

  state = {
    focused: false,
    showList: false
  };

  constructor(props) {
    super(props);

    this.select = createRef();
    this.selectInput = createRef();
    this.handleClickOutsideSelect = this.handleClickOutsideSelect.bind(this);
  }

  handleClickOutsideSelect(event) {
    if (
      this.select.current &&
      !this.select.current.contains(event.target)
    ) {
      this.setState({ showList: false });
    }
  }

  componentDidMount() {
    document.addEventListener(
      'mousedown',
      this.handleClickOutsideSelect,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleClickOutsideSelect,
      false
    );
  }

  handleItemClicked = item => {
    this.props.itemClicked(item.value);
    this.handleToggleListVisibility();
  };

  handleToggleListVisibility = () => {
    const showList = !this.state.showList;

    if (showList && this.selectInput.current) {
      this.selectInput.current.focus();
    }

    this.setState({
      showList,
      focused: showList ? showList : this.state.focused
    });
  };

  getLabelFromValue = (value: string) => {
    if(!value)
      return '';

    const data = GENDERS.find((item: ISelectItem) => item.value === value);

    return data ? data.label : '';
  };

  render (){
    const { disabled, options, placeholderTextId, value, size } = this.props;
    const { showList } = this.state;

    const allOptions = options.map((item: ISelectItem, i: number) => {
      return (
        <SelectItem
          key={i}
          itemClicked={this.handleItemClicked}
          item={item}/>
      );
    });

    return (
      <div
        ref={this.select}
         className={[
           classes.Select,
           size ? classes[`Select--${size}`] : '',
           showList ? classes['is-open'] : '',
           disabled ? classes['is-disabled'] : ''
         ].join(' ')}>
        <FormattedMessage id={placeholderTextId} defaultMessage={''}>
          {(placeholder: any) => (
            <button
              className={[
                classes['Select-button'],
                showList ? classes['is-open'] : '',
                value ? classes['Select-button--withValue'] : '',
              ].join(' ')}
              onClick={() => this.setState({ showList: !showList })}
              disabled={disabled}
            >
              <span>{ this.getLabelFromValue(value) || placeholder }</span>
              <img src={arrowDownIcon} className={classes['Select-icon']} alt={'arrow'} />
            </button>
          )}
        </FormattedMessage>
        <div className={[classes['Select-list'], showList ? classes['is-visible'] : ''].join(' ')}>{ allOptions }</div>
      </div>
    );
  }
}

export default Select;
