import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './styles.module.scss';

interface IProps {
	children?: object | string;
	clicked?: () => void;
	active?: boolean;
	styles?: object;
	disabled?: boolean;
	redirectTo?: string;
  size?: 'small' | 'medium' | 'large';
  mouseOut?: () => void;
  mouseOver?: () => void;
  theme?: 'light' | 'dark';
  type?: 'link' | 'button';
  hideEffect?: boolean;
  icon?: {
    position: 'right' | 'left';
    src: string;
  }
}

class Button extends Component<IProps> {

  render() {
    const { children, clicked, styles, disabled, redirectTo, size, theme, active, type, hideEffect, icon } = this.props;

    const buttonClasses = [
      classes.Button,
      theme ? classes[`Button--${theme}`] : '',
      size ? classes[`Button--${size}`] : '',
      active ? classes[`is-active`] : '',
      type ? classes[`Button--${type}`] : '',
      hideEffect ? classes[`Button--noEffect`] : '',
    ].join(' ');

    let content;

    if(icon){
      if(icon.position === 'left'){
        content =
          <Fragment>
            <img className={[classes['Button-icon'], classes['Button-icon--left']].join(' ')} src={icon.src} alt={'icon'} />
            <span>{ children }</span>
          </Fragment>
      } else {
        content =
          <Fragment>
            <span>{ children }</span>
            <img className={[classes['Button-icon'], classes['Button-icon--right']].join(' ')} src={icon.src} alt={'icon'} />
          </Fragment>
      }
    } else {
      content = (
        <span>{ children }</span>
      )
    }

    return redirectTo ?
      <NavLink
        className={buttonClasses}
        to={redirectTo}>
        { content }
      </NavLink> :
      <button
        type={'button'}
        disabled={disabled}
        style={styles}
        onClick={clicked}
        className={buttonClasses}>
        { content }
      </button>;
  }
}

export default Button;
