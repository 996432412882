import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import constants from '../../shared/constants';
import * as routes from '../../shared/routes';

// Routes
import Home from '../../components/Home';
import Contact from '../../components/Contact';
import Woman from '../../components/Woman';
import Talent from '../../components/Talent';
import ModelDetails from '../../components/ModelDetails';
import ScoutingForm from '../../components/Scouting';
import News from '../../components/News';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import ThankYou from '../../components/ThankYou';

class RouterWrapper extends Component {

  render() {
    return (
      <Switch>
        <Route exact={true} path={routes.HOME} component={Home}/>
				<Route exact={true} path={routes.CONTACT} component={Contact}/>
        {
          !constants.DISABLE_WOMEN &&
            <Route exact={true} path={routes.WOMEN} component={Woman}/>
        }
        {
          !constants.DISABLE_TALENT &&
            <Route exact={true} path={routes.TALENT} component={Talent}/>
        }
        {
          !constants.DISABLE_WOMEN &&
            <Route exact={true} path={routes.MODEL} component={ModelDetails}/>
        }
				{
				  !constants.DISABLE_SCOUTING &&
					  <Route exact={true} path={routes.SCOUTING} component={ScoutingForm} />
				}
				<Route exact={true} path={routes.NEWS} component={News} />
				<Route exact={true} path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact={true} path={routes.THANK_YOU} component={ThankYou} />
        <Redirect to={routes.HOME} />
      </Switch>
    );
  }
}

export default RouterWrapper;
