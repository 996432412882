import React from 'react';
import classes from './styles.module.scss';

interface paragraphProps {
	children: any;
	styles?: object;
	size?: string;
}

const paragraph = ({
	children,
	styles,
	size,
}: paragraphProps) => {
	const paragraphClass = [
		classes.Paragraph,
		size ? classes[`Paragraph--${size}`] : ''
	].join(' ');

	return (
		<div className={paragraphClass} style={styles}>
			{ children }
		</div>
	);
};

export default paragraph;
