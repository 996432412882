import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import constants from '../../shared/constants';
import { JOIN_FORM, UNITS } from '../../shared/data';
import { checkValidEmail, checkValidPhone } from '../../shared/helpers';
import { IFormInput, IJoinData, IModelStore, IScoutingFormState } from '../../shared/interfaces';
import CheckBox from '../CheckBox';
import Radio from '../Radio';
import Select from '../Select/Select';
import ThankYou from '../ThankYou';
import classes from './styles.module.scss';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Button from '../Button';
import Input from '../Input';
import Title from '../Title';
import UploadBox from '../UploadBox';
import Label from '../Label';
import Paragraph from '../Paragraph';
import triangle from '../../assets/images/triangles/03tr.svg';

import Scroll from 'react-scroll';
import { when } from 'mobx';
const Element = Scroll.Element;
const scroller = Scroll.scroller;

const LIMIT_IMAGES_SIZES = 50*1024000;

interface IProps {
	modelStore: IModelStore;
}

@inject('modelStore')
@observer
class ScoutingForm extends Component<IProps, IScoutingFormState> {
	state = {
		personalInfo: JOIN_FORM.personal,
		unit: 'metric',
		measurements: JOIN_FORM.measurements,
		files: [undefined, undefined, undefined],
		previews: ['', '', ''],
		sending: false,
		feedback: '',
		video: '',
		privacy: false,
		newsletter: false,
		imagesSizesError: false,
	};

	componentDidMount(): void {
		this.props.modelStore.resetJoin();

		when(
			() => this.props.modelStore.joined,
			() => {
				scroller.scrollTo('Content', {
					duration: 1200,
					smooth: true,
					offset: -40
				})
			}
		);
	}

	onFormChanged = (form: string, name: string, value: string) => {
		//console.log(form, name, value)
		if(form === 'personalInfo') {
			let personalInfo = [...this.state.personalInfo].map((input: IFormInput) => {
				return {
					...input,
					value: name === input.name ? value : input.value,
					error: false,
				}
			});

			this.setState({
				personalInfo
			})
		}

		if(form === 'measurements') {
			let measurements = [...this.state.measurements].map((input: IFormInput) => {
				return {
					...input,
					value: name === input.name ? value : input.value
				}
			});

			this.setState({
				measurements
			})
		}
	};

	isSubmitDisabled = () => {
		const { personalInfo, measurements, previews, privacy } = this.state;

		if(!privacy || previews.filter((item: string) => item === '').length === 3)
			return true;

		if(personalInfo.filter((item: IFormInput) => item.value.trim() === '' && item.required).length)
			return true;

		return !!measurements.filter((item: IFormInput) => item.value.trim() === '' && item.required).length;
	};

	onRemovePhotoClicked = (index: number) => {
		let files = [...this.state.files];
		let previews = [...this.state.previews];

		files[index] = undefined;
		previews[index] = '';

		this.setState({
			previews,
			files
		}, () => {
			this.validateUploadedImages();
		})
	};

	onPhotoChanged = (acceptedFiles: Array<File>, rejectedFiles: Array<File>, index: number) => {
		if (acceptedFiles.length === 0)
			return;

		const reader = new FileReader();
		reader.readAsDataURL(acceptedFiles[0]);
		reader.onload = (_event) => {
			let previews = [...this.state.previews];
			let files = [...this.state.files];
			if(reader.result) {
				previews[index] = reader.result as string;
				// @ts-ignore
				files[index] = acceptedFiles[0];

				this.setState({
					previews,
					files
				}, () => {
					this.validateUploadedImages();
				})
			}
		};
	};

	validateUploadedImages = () => {
		// check images sizes
		let imageSizes = 0;
		// @ts-ignore
		this.state.files.forEach((file: File) => {
			if(file)
				imageSizes += file.size;
		});

		const imagesSizesError =  imageSizes > LIMIT_IMAGES_SIZES;

		this.setState({
			imagesSizesError
		});

		return !imagesSizesError;
	};

	validation = () => {
		let valid = true;
		let personalInfo = [...this.state.personalInfo];

		const emailField = personalInfo.find((item: IFormInput) => item.name === 'email');
		const phoneField = personalInfo.find((item: IFormInput) => item.name === 'phone');

		if(emailField && !checkValidEmail(emailField.value)) {
			valid = false;
			personalInfo = personalInfo.map((item: IFormInput) => {
				if(item.name === 'email') {
					item.error = true;
				}

				return item;
			});

			this.setState({
				personalInfo
			})
		}

		if(phoneField && phoneField.value.trim().length > 0 && !checkValidPhone(phoneField.value)) {
			valid = false;
			personalInfo = personalInfo.map((item: IFormInput) => {
				if(item.name === 'phone') {
					item.error = true;
				}

				return item;
			});

			this.setState({
				personalInfo
			})
		}

		if(!valid) {
			scroller.scrollTo('PersonalInfo', {
				duration: 1200,
				smooth: true,
				offset: -40
			})
		}

		return valid;
	};

	sendEmail = () => {
		if(this.validation()) {
			const { personalInfo, unit, measurements, privacy, newsletter, video } = this.state;

			const personalData = Object.assign({}, ...personalInfo.map((input: IFormInput) => {
				return {
					[input.name]: input.value
				}
			}));

			const measurementsData = Object.assign({}, ...measurements.map((input: IFormInput) => {
				return {
					[input.name]: input.value
				}
			}));

			const data: IJoinData = {
				...personalData,
				...measurementsData,
				unit,
				privacy,
				newsletter,
				video,
				previews: this.state.previews.map((item: string) => {
					return {
						path: item
					}
				})
			};

			this.props.modelStore.join(data);
		}
	};

	render() {
		const {
			personalInfo,
			measurements,
			previews,
			unit,
			video,
			privacy,
			newsletter,
			imagesSizesError
		} = this.state;

		const {
			modelStore: {
				isJoining,
				joinError,
				joined
			}
		} = this.props;

		const personalInfoTpl = personalInfo.map((input: IFormInput, index: number) => {
			return (
				input.type !== 'select' ?
					<Input
						size={input.size}
						key={index}
						value={input.value}
						name={input.name}
						changed={(value) => this.onFormChanged('personalInfo', input.name, value)}
						placeholderTextId={input.placeholderTextId}
						required={input.required}
						error={input.error || false}
						lowercase={input.lowercase || false}
						type={input.type}
						capitalize={input.capitalize || false} /> :
					<Select
						size={input.size}
						key={index}
						placeholderTextId={input.placeholderTextId}
						options={input.options || []}
						name={input.name}
						itemClicked={(gender: string) => this.onFormChanged('personalInfo', input.name, gender)}
						value={input.value} />
			)
		});

		const measurementsTpl = measurements.map((input: IFormInput, index: number) => {
			return (
				<Input
					size={input.size}
					key={index}
					value={input.value}
					name={input.name}
					type={input.type}
					changed={(value) => this.onFormChanged('measurements', input.name, value )}
					placeholderTextId={input.placeholderTextId}
					required={input.required}
					capitalize={input.capitalize || false} />
			)
		});

		return (
			<div className={classes.ScoutingForm}>
				<div className={classes['ScoutingForm-header']}>
					<img src={triangle} alt={'Fabbrica'} />
					<Title
						uppercase={true}
						alignment={'center'}>
						<FormattedHTMLMessage id={'join.title'} />
					</Title>
				</div>
				<p className={classes['ScoutingForm-subtitle']}>
					<FormattedHTMLMessage id={'join.subtitle'} />
				</p>
				<Element
					name="Content">
					{
						joined ?
							<ThankYou /> :
							<>
								<div className={classes['ScoutingForm-content']}>
									<Element
										name="PersonalInfo">
										<Label
											styles={{margin: '0 0 2.5rem'}}>
											<FormattedMessage id={'label.info'} />
										</Label>
										<div className={classes['ScoutingForm-row']}>
											{
												personalInfoTpl
											}
										</div>
									</Element>
									<div className={classes['ScoutingForm-measurements']}>
										<Label>
											<FormattedMessage id={'label.measure'} />
										</Label>
										<div className={classes['ScoutingForm-unit']}>
											<Radio
												items={UNITS}
												value={unit}
												clicked={(unit) => this.setState({ unit })} />
										</div>
										<div className={classes['ScoutingForm-row']}>
											{ measurementsTpl }
										</div>
									</div>
									<div className={classes['ScoutingForm-upload']}>
										<Label>
											<FormattedMessage id={'label.upload'} />
										</Label>
										<div className={classes['ScoutingForm-boxes']}>
											<UploadBox
												accept={'image/jpeg, image/png'}
												preview={previews[0]}
												onRemoveClicked={() => this.onRemovePhotoClicked(0)}
												onFilesSelected={(acceptedFiles, rejectedFiles) => this.onPhotoChanged(acceptedFiles, rejectedFiles, 0)}
												title={<FormattedMessage id={'placeholder.photo.one'} />} />
											<UploadBox
												accept={'image/jpeg, image/png'}
												preview={previews[1]}
												onRemoveClicked={() => this.onRemovePhotoClicked(1)}
												onFilesSelected={(acceptedFiles, rejectedFiles) => this.onPhotoChanged(acceptedFiles, rejectedFiles, 1)}
												title={<FormattedMessage id={'placeholder.photo.two'} />} />
											<UploadBox
												accept={'image/jpeg, image/png'}
												preview={previews[2]}
												onRemoveClicked={() => this.onRemovePhotoClicked(2)}
												onFilesSelected={(acceptedFiles, rejectedFiles) => this.onPhotoChanged(acceptedFiles, rejectedFiles, 2)}
												title={<FormattedMessage id={'placeholder.photo.three'} />} />
										</div>
										{
											imagesSizesError &&
												<div style={{margin: '1rem 0 0'}} className={[classes['ScoutingForm-feedback'], classes['ScoutingForm-feedback--error']].join(' ')}>
													{ 'Images are too big. Please resize or remove.' }
												</div>
										}
										<div className={classes['ScoutingForm-video']}>
											<Input
												dynamic={true}
												size={'large'}
												value={video}
												name={'video'}
												changed={(video) => this.setState({ video })}
												placeholderTextId={'placeholder.video'} />
										</div>
									</div>
								</div>
								<div className={classes['ScoutingForm-disclaimers']}>
									{
										<FormattedMessage id={'check.privacy'}>
											{
												privacyText => {
													return (
														<CheckBox
															clicked={(privacy) => this.setState({ privacy })}
															checked={privacy}
															// @ts-ignore
															text={privacyText.replace('--privacyUrl--', constants.PRIVACY_POLICY_LINK)} />
													)
												}
											}
										</FormattedMessage>
									}
									{
										<FormattedMessage id={'check.newsletter'}>
											{
												newsletterText => {
													return (
														<CheckBox
															clicked={(newsletter) => this.setState({ newsletter })}
															checked={newsletter}
															// @ts-ignore
															text={newsletterText} />
													)
												}
											}
										</FormattedMessage>
									}
									<span className={classes['ScoutingForm-required']}>
									<FormattedMessage id={'label.required'} />
								</span>
								</div>
								<div className={classes['ScoutingForm-footer']}>
									<Button
										disabled={this.isSubmitDisabled() || imagesSizesError}
										clicked={this.sendEmail}>
										<FormattedMessage id={'button.submit'} />
									</Button>
								</div>
								<div className={classes['ScoutingForm-actions']}>
									{
										isJoining &&
										<div className={classes['ScoutingForm-loading']}>
											Sending...
										</div>
									}
									<div className={classes['ScoutingForm-feedback']}>
										{ joined ? 'Message sent.' : joinError }
									</div>
								</div>
							</>
					}
				</Element>
				<div className={classes['ScoutingForm-tips']}>
					<Label>
						<FormattedMessage id={'label.tips'} />
					</Label>
					<div>
						<Paragraph>
							<FormattedMessage id={'text.tips.one'} />
						</Paragraph>
						<Paragraph>
							<FormattedMessage id={'text.tips.two'} />
						</Paragraph>
					</div>
				</div>
				<div className={classes['ScoutingForm-warning']}>
					<Label>
						<FormattedMessage id={'label.recruitmentWarning'} />
					</Label>
					<div>
						<FormattedHTMLMessage id={'text.recruitmentWarning'} />
					</div>
				</div>
			</div>
		);
	}
}

export default ScoutingForm;
