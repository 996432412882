import React, { Component, Fragment } from 'react';
import { IModelNav, IModelStore } from '../../shared/interfaces';
import classes from './styles.module.scss';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react';

interface IProps {
	styles?: object;
	items: Array<IModelNav>;
	theme?: 'lightBox' | 'default';
	title?: string;
	showDigitals?: boolean;
	showVideos?: boolean;
	modelStore: IModelStore;
}

@inject("modelStore")
@observer
class ModelNavigation extends Component<IProps> {
	render () {
		const { items, styles, theme, title, showDigitals, showVideos } = this.props;

		const instagramSocial = this.props.modelStore.currentModel && this.props.modelStore.currentModel.socials.find(s => s.type === "Instagram")
		const navTpl = items.filter((i: IModelNav) => i.visible).map((item: IModelNav, index: number) => {
			if (item.key === 'instagram') {
				const followers = (instagramSocial?.followers && instagramSocial?.followers != "0") ? <div className={classes['InstagramLink-followers']}><strong>{instagramSocial.followers}</strong> followers</div> : ''
				return instagramSocial === undefined
					? null
					: <div className={classes['InstagramLink']}><button
							key={index}
							className={[
								classes['ModelNavigation-item'],
								item.active ? classes['is-active'] : '',
							].join(' ')}
							onClick={() => item.clicked(index, { type: "Instagram", link: instagramSocial.link })}
						>
							{ item.label }
						</button>
						{followers}
					</div>
			}
			if(item.key === 'digitals' && !showDigitals){
				return null;
			}
			if(item.key === 'video' && !showVideos){
				return null;
			}

			return (
				<button
					key={index}
					className={[
						classes['ModelNavigation-item'],
						item.active ? classes['is-active'] : '',
					].join(' ')}
					onClick={() => item.clicked(index)}
				>
					{ item.label }
				</button>
			)
		});

		return (
			<div
				className={[
					classes.ModelNavigation,
					theme ? classes[`ModelNavigation--${theme}`] : ''
				].join(' ')}
				style={styles}>
				{
					title &&
						<Fragment>
							<h2 className={classes['ModelNavigation-title']}>{ title }</h2>
							<span className={classes['ModelNavigation-separator']} />
						</Fragment>
				}
				<div className={classes['ModelNavigation-items']}>
					{navTpl}
				</div>
			</div>
		);
	}
}

export default ModelNavigation;
