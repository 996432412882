import React from 'react';
import classes from './style.module.scss';
import Dropzone from 'react-dropzone';

interface IProps {
  title: any;
  styles?: object;
  multiple?: boolean;
  accept?: string | undefined;
  progress?: { value: number; show: boolean };
  files?: any[];
  disabled?: boolean;
  onFilesSelected?: (uploaded: File[], rejected: File[]) => void;
  onRemoveClicked: () => void;
  preview?: string;
  maxSize?: number;
}

const uploadBox = ({
  title,
  styles,
  multiple,
  accept,
  onFilesSelected = () => {},
  disabled,
  preview,
  maxSize,
  onRemoveClicked
}: IProps) => {
  const onDrop = async (acceptedFiles : File[], rejectedFiles : File[]) => {
    if(rejectedFiles.length > 0){
      alert('This photo cannot be accepted. Max size for photo: 5 MB. Please resize.');
    } else {
      onFilesSelected(acceptedFiles, rejectedFiles);
    }
  };
  return (
    <div className={classes.UploadBox}>
      {
        preview &&
          <button className={classes['UploadBox-remove']} onClick={onRemoveClicked}>{'Remove'}</button>
      }
      <Dropzone
        onDrop={onDrop}
        maxSize={maxSize || 5120000}
        disabled={disabled || false}
        multiple={multiple ? multiple : false}
        accept={accept}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              style={styles}
              className={[
                [
                  classes['UploadBox-content'],
                  disabled ? classes['is-disabled'] : ''
                ].join(' '),
                isDragActive ? classes['is-active'] : ''
              ].join(' ')}>
              <input {...getInputProps()} />
              {isDragActive && !preview ? (
                <span className={classes['UploadBox-title']}>
									{
										title
									}
                </span>
              ) : (
                <>
                  {
                    preview ?
                      <div
                        style={{
                          backgroundImage: `url(${preview})`
                        }}
                        className={classes['UploadBox-preview']} /> :
                      <div className={classes['UploadBox-title']}>
                        {
                          title
                        }
                      </div>
                  }
                </>
              )}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default uploadBox;
