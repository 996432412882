import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import constants from '../constants';
import * as routes from '../routes';
import {
  IContact,
  INav,
  IFormInput,
  IUnit,
  ICover,
  IModelNav,
  IModelsTicker,
  ISelectItem,
  IPrivacyPolicyParagraph, IVideo,
} from '../interfaces';

// Cover
import triangle1 from '../../assets/images/cover/tr16.png';
import cover1 from '../../assets/images/cover/1.jpg';
import triangle2 from '../../assets/images/cover/tr1.png';
import cover2 from '../../assets/images/cover/2.jpg';
import triangle3 from '../../assets/images/cover/tr2.png';
import cover3 from '../../assets/images/cover/3.jpg';
import triangle4 from '../../assets/images/cover/tr3.png';
import cover4 from '../../assets/images/cover/4.jpg';
import triangle5 from '../../assets/images/cover/tr4.png';
import cover5 from '../../assets/images/cover/5.jpg';
import triangle6 from '../../assets/images/cover/tr5.png';
import cover6 from '../../assets/images/cover/6.jpg';
import triangle7 from '../../assets/images/cover/tr6.png';
import cover7 from '../../assets/images/cover/7.jpg';
import triangle8 from '../../assets/images/cover/tr7.png';
import cover8 from '../../assets/images/cover/8.jpg';
import triangle9 from '../../assets/images/cover/tr8.png';
import cover9 from '../../assets/images/cover/9.jpg';
import triangle10 from '../../assets/images/cover/tr9.png';
import cover10 from '../../assets/images/cover/10.jpg';
import triangle11 from '../../assets/images/cover/tr10.png';
import cover11 from '../../assets/images/cover/13.jpg';
import triangle12 from '../../assets/images/cover/tr13.png';
import cover12 from '../../assets/images/cover/14.jpg';
import triangle13 from '../../assets/images/cover/tr14.png';
import cover13 from '../../assets/images/cover/15.jpg';
import triangle14 from '../../assets/images/cover/tr15.png';
import cover14 from '../../assets/images/cover/16.jpg';

export const FAKE_VIDEOS: Array<IVideo> = [
  {
    url: 'https://vimeo.com/374410050',
    thumbnail: '',
  },
  {
    url: 'https://vimeo.com/370937766',
    thumbnail: '',
  },
];

export const MODELS: Array<IModelsTicker> = [
  {
    name: 'FREDERIKKE',
    brand: 'VOGUE Spain',
    url: 'https://www.instagram.com/p/B1nqWF-I3ks/',
  },
  {
    name: 'STEINBERG',
    brand: 'Zara',
    url: 'https://www.instagram.com/p/B1ayqs3oZMG/',
  },
  {
    name: 'SONYA',
    brand: 'VOGUE',
    url: 'https://www.instagram.com/p/B1IxciHo_Vk/',
  },
  {
    name: 'SIMONA',
    brand: 'YSL',
    url: 'https://www.instagram.com/p/B1GFGttoAhQ/',
  },
  {
    name: 'ALTYN SIMPSON',
    brand: 'POLO - Ralph Lauren',
    url: 'https://www.instagram.com/p/B06H7amoigM/',
  },
  {
    name: 'STEINBERG',
    brand: 'LOUIS VUITTON',
    url: 'https://www.instagram.com/p/B030R4yoj54',
  },
];

export const NAV: Array<IModelNav> = [
  {
    id: 1,
    key: 'portfolio',
    label: <FormattedMessage id={'navigation.portfolio'} />,
    clicked: index => {},
    visible: true
  },
  {
    id: 2,
    key: 'digitals',
    label: <FormattedMessage id={'navigation.digitals'} />,
    clicked: index => {},
    visible: true
  },
  {
    id: 4,
    key: 'video',
    label: <FormattedMessage id={'navigation.video'} />,
    clicked: index => {},
    visible: true
  },
	{
		id: 3,
		key: 'instagram',
		label: <FormattedMessage id={'navigation.instagram'} />,
		clicked: index => {},
		visible: true
	},
];

export const CONTACTS: Array<IContact> = [
  {
    label: <FormattedMessage id={'label.office'} />,
    values: ['VIA NIRONE, 2', '20123 MILANO - Italia']
  },
  {
    label: <FormattedMessage id={'label.mail'} />,
    values: ['<a href="mailto:info@fabbricamilano.com">INFO@FABBRICAMILANO.com</a>']
  },
  {
    label: <FormattedMessage id={'label.phone'} />,
    values: ['+39 02 4210 7213']
  },
  {
    label: <FormattedMessage id={'label.followUs'} />,
    values: ['<a href="https://www.facebook.com/fabbrica.management/" target="_blank">Facebook</a>', '<a href="https://www.instagram.com/fabbrica.milano/" target="_blank">Instagram</a>']
  },
];

export const NAVIGATION: Array<INav> = [
  {
    route: routes.HOME,
    label: <FormattedMessage id={'navigation.home'} />,
    key: 'home',
    visible: true,
  },
  {
    route: routes.WOMEN,
    label: <FormattedMessage id={'navigation.women'} />,
    key: 'women',
    visible: !constants.DISABLE_WOMEN,
  },
  {
    route: routes.TALENT,
    label: <FormattedMessage id={'navigation.talent'} />,
    key: 'talent',
    visible: !constants.DISABLE_TALENT,
  },
  {
    route: routes.SHOP,
    label: <FormattedMessage id={'navigation.shop'} />,
    key: 'shop',
    visible: true,
  },
  {
    route: routes.SCOUTING,
    label: <FormattedMessage id={'navigation.scouting'} />,
    key: 'scouting',
    visible: !constants.DISABLE_SCOUTING,
  },
  {
    route: routes.NEWS,
    label: <FormattedMessage id={'navigation.news'} />,
    key: 'news',
    visible: false,
  },
	{
		route: routes.CONTACT,
		label: <FormattedMessage id={'navigation.contact'} />,
		key: 'contact',
		visible: true,
	},
];

export const GENDERS: Array<ISelectItem> = [
  {
    value: 'M',
    label: <FormattedMessage id={'gender.male'} />
  },
  {
    value: 'F',
    label: <FormattedMessage id={'gender.female'} />
  },
];

export const JOIN_FORM: {
  personal: Array<IFormInput>,
  measurements: Array<IFormInput>,
} = {
  personal: [
    {
      name: 'firstName',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.firstName',
      capitalize: true,
      required: true,
    },
    {
      name: 'lastName',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.lastName',
      capitalize: true,
      required: true,
    },
    {
      name: 'birthday',
      value: '',
      type: 'date',
      placeholderTextId: 'placeholder.birthday',
      required: true,
      size: 'medium',
    },
    {
      name: 'gender',
      value: '',
      type: 'select',
      placeholderTextId: 'placeholder.gender',
      size: 'medium',
      options: GENDERS,
    },
    {
      name: 'email',
      value: '',
      type: 'email',
      placeholderTextId: 'placeholder.email',
      required: true,
      lowercase: true,
    },
    {
      name: 'phone',
      value: '',
      type: 'tel',
      placeholderTextId: 'placeholder.phone',
    },
    {
      name: 'address',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.address',
    },
    {
      name: 'city',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.city',
      required: true,
      size: 'large',
    },
    {
      name: 'postalCode',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.postalCode',
      size: 'small',
    },
    {
      name: 'country',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.country',
      required: true,
    },
  ],
  measurements: [
    {
      name: 'height',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.height',
      size: 'medium',
      required: true,
    },
    {
      name: 'bust',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.bust',
      size: 'medium',
      required: true,
    },
    {
      name: 'waist',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.waist',
      size: 'medium',
      required: true,
    },
    {
      name: 'hips',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.hips',
      size: 'medium',
      required: true,
    },
    {
      name: 'dressSize',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.dressSize',
      size: 'medium',
      required: true,
    },
    {
      name: 'shoeSize',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.shoeSize',
      size: 'medium',
      required: true,
    },
    {
      name: 'hair',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.hair',
      size: 'medium',
      required: true,
    },
    {
      name: 'eyes',
      value: '',
      type: 'text',
      placeholderTextId: 'placeholder.eyes',
      size: 'medium',
      required: true,
    },
  ]
};

export const UNITS: Array<IUnit> = [
  {
    label: <FormattedMessage id={'button.metric'} />,
    key: 'metric'
  },
  {
    label: <FormattedMessage id={'button.imperial'} />,
    key: 'imperial'
  },
];

export const COVERS: Array<ICover> = [
  {
    background: cover1,
    triangle: triangle1
  },
  {
    background: cover2,
    triangle: triangle2
  },
  {
    background: cover3,
    triangle: triangle3
  },
  {
    background: cover4,
    triangle: triangle4
  },
  {
    background: cover5,
    triangle: triangle5
  },
  {
    background: cover6,
    triangle: triangle6
  },
  {
    background: cover7,
    triangle: triangle7
  },
  {
    background: cover8,
    triangle: triangle8
  },
  {
    background: cover9,
    triangle: triangle9
  },
  {
    background: cover10,
    triangle: triangle10
  },
  {
    background: cover11,
    triangle: triangle11
  },
  {
    background: cover12,
    triangle: triangle12
  },
  {
    background: cover13,
    triangle: triangle13
  },
  {
    background: cover14,
    triangle: triangle14
  },
];

export const PRIVACY_POLICY_PARAGRAPHS: Array<IPrivacyPolicyParagraph> = [
  {
    title: <FormattedMessage id={'privacy.one.title'} />,
    content: <FormattedHTMLMessage id={'privacy.one.content'} />
  },
  {
    title: <FormattedMessage id={'privacy.two.title'} />,
    content: <FormattedHTMLMessage id={'privacy.two.content'} />
  },
  {
    title: <FormattedMessage id={'privacy.three.title'} />,
    content: <FormattedHTMLMessage id={'privacy.three.content'} />
  },
  {
    title: <FormattedMessage id={'privacy.four.title'} />,
    content: <FormattedHTMLMessage id={'privacy.four.content'} />
  },
  {
    title: <FormattedMessage id={'privacy.five.title'} />,
    content: <FormattedHTMLMessage id={'privacy.five.content'} />
  },
  {
    title: <FormattedMessage id={'privacy.six.title'} />,
    content: <FormattedHTMLMessage id={'privacy.six.content'} />
  },
  {
    title: <FormattedMessage id={'privacy.seven.title'} />,
    content: <FormattedHTMLMessage id={'privacy.seven.content'} />
  },
  {
    title: <FormattedMessage id={'privacy.eight.title'} />,
    content: <FormattedHTMLMessage id={'privacy.eight.content'} />
  },
];
