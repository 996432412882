import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { getViewPortSize } from '../../shared/helpers';
import { IInstagramStore, IModelNav, IModelStore } from '../../shared/interfaces';
import Features from '../Features';
import ModelLightBox from '../ModelLightBox';
import ModelNavigation from '../ModelNavigation';
import classes from './styles.module.scss';
import { NAV } from '../../shared/data';
import './styles.scss';

import triangle1 from '../../assets/images/triangles/animated-triangles--white.gif';
import ReactAliceCarousel from "react-alice-carousel";

const responsive = {
	0: { items: 1 },
	992: { items: 2 },
};

interface IProps {
	modelStore: IModelStore;
	instagramStore: IInstagramStore;
}

let destroy = false;

interface IState {
	showLightBox: boolean;
	currentNavId: number;
	imageHeight: string;
	autoPlay: boolean;
}

@inject('modelStore')
@inject('instagramStore')
@observer
export default class ModelCover extends Component<IProps, IState> {
	carousel = React.createRef<ReactAliceCarousel>();

	state = {
		showLightBox: false,
		currentNavId: 0,
		imageHeight: '50rem',
		autoPlay: true,
	};

	componentDidMount(): void {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
	}

	componentWillUnmount(): void {
		destroy = true;
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		const sizes = getViewPortSize();

		if(sizes){
			this.setState({
				imageHeight: sizes.width >= 768 ? `${sizes.height}px` : '50rem'
			});
		}
	};

	showLightBox = (id: number) => {
		this.setState({
			showLightBox: true,
			currentNavId: id
		})
	};

	onNext = (index: number) => {
		if(this.carousel.current !== null && destroy){
			let curIndex = index;
			this.carousel.current.slideTo(curIndex);
			curIndex += 2;

			setTimeout(() => {
				if(this.carousel.current) {
					this.carousel.current.slideTo(curIndex);
					curIndex += 2;


					setTimeout(() => {
						if(this.carousel.current !== null) {
							this.carousel.current.slideTo(curIndex);

							this.setState({
								autoPlay: true
							})
						}
					}, 3500)
				}
			}, 3500);
		}
	};

	onSlideChange = (e: EventObject) => {
		const sizes = getViewPortSize();

		if(e.item === 0 && this.carousel.current !== null && sizes && sizes.width >= 992){
			this.setState({
				autoPlay: false,
			}, () => {
				setTimeout(() => {
					this.onNext(3)
				}, 3500)
			})
		}
	};

	render() {
		const { modelStore, instagramStore } = this.props;
		const { showLightBox, currentNavId, autoPlay } = this.state;

		const navItems = NAV.map((nav: IModelNav) => {
			return {
				...nav,
				visible: nav.visible,
				clicked: nav.key === "instagram"
					? (_, payload) => {
						if (payload && payload.type === "Instagram") {
							const win = window.open(payload.link, '_blank')
							win && win.focus()
						}
					}
					:() => this.showLightBox(nav.id),
				active: nav.id === currentNavId,
			}
		});

		const images = modelStore.currentModel && modelStore.currentModel.pictures.slice(0, 7).map((imgUrl: string, index: number) => {
			return (
				<div
					key={index}
					style={{
						backgroundImage: `url(${imgUrl})`,
						height: this.state.imageHeight
					}}
					className={classes['ModelCover-image']}>
					<div className={classes['ModelCover-overly']} />
				</div>
			)
		});

		return (
			<div className={classes.ModelCover}>
				{
					modelStore.currentModel ?
						<Fragment>
							<div className={classes['ModelCover-gallery']}>
								<AliceCarousel
									ref={this.carousel}
									swipeDisabled={true}
									autoPlayInterval={3500}
									autoPlay={autoPlay}
									buttonsDisabled={true}
									dotsDisabled={true}
									onSlideChange={this.onSlideChange}
									responsive={responsive}>
									{
										images
									}
								</AliceCarousel>
							</div>
							<div className={classes['ModelCover-content']}>
								<img src={triangle1} className={classes['ModelCover-logo']} alt={'fabbrica'} />
								<div className={classes['ModelCover-name']}>
									{ modelStore.currentModel.fullName }
								</div>
								<ModelNavigation
									showDigitals={modelStore.currentModel.digitals && modelStore.currentModel.digitals.length > 0}
									showVideos={modelStore.currentModel.videos && modelStore.currentModel.videos.length > 0}
									modelStore={modelStore}
									items={navItems} />
								<Features items={modelStore.currentModel.features} />
							</div>
						</Fragment> : null
				}
				{
					showLightBox &&
						<ModelLightBox
							modelStore={modelStore}
							instagramStore={instagramStore}
							currentNavId={currentNavId}
							navItems={navItems}
							show={showLightBox}
							closeClicked={() => this.setState({ showLightBox: false })} />
				}
			</div>
		);
	}
}
