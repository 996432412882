import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { IVideo } from '../../shared/interfaces';
import classes from './styles.module.scss';

export default class Video extends Component<IVideo> {
  render(){
    const { url } = this.props;

    return (
      <div
        className={[
          classes.Video,
        ].join(' ')}>
        <ReactPlayer
          className={classes['Video-player']}
          width='100%'
          height='100%'
          url={url}
          controls={true} />
      </div>
    )
  }
}
