import React, { Component } from 'react';
import classes from './styles.module.scss';

interface IProps {
  text: string;
  clicked: (checked: boolean) => void;
  checked?: boolean;
  styles?: object;
}

class CheckBox extends Component<IProps> {

  render() {
    const { checked, text, styles, clicked } = this.props;

    return (
      <div
        style={styles}
        className={classes['CheckBox']}>
        <button
          onClick={() => clicked(!checked)}
          className={[
            classes['CheckBox-input'],
            checked ? classes['is-checked'] : ''
          ].join(' ')} />
        <div className={classes['CheckBox-text']} dangerouslySetInnerHTML={{__html: text}} />
      </div>
    )
  }
}

export default CheckBox;
