import { observable, action, computed } from 'mobx';
import axios from 'axios';
import { IFeature, IJoinData, IModelsTicker, IModelStore } from '../shared/interfaces';
import constants from "../shared/constants";

const LOCAL_API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ''}${process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

export class ModelStore implements IModelStore {
  @observable models = [];
  @observable mainModels: Array<IModelsTicker> = [];
  @observable currentModel;
  @observable error = null;
  @observable isFetching = false;
  @observable isJoining = false;
  @observable joined = false;
  @observable joinError = null;

  @action.bound
  fetchModel(id: number) {
    this.isFetching = true;
    this.error = null;
		this.currentModel = null;

    try {
      const endpoint = `${LOCAL_API_BASE_URL}/api/model/${id}`;

      axios.get(endpoint).then(response => {
				if(response.data.fname && response.data.cover){
					const book = response.data.books.length && response.data.books.find((book) => book.title.toLowerCase() === 'book');
					const digitals = response.data.books.length && response.data.books.find((book) => book.title.toLowerCase().includes('pola'));

					this.currentModel = {
						id: response.data.id,
						firstName: response.data.fname,
						lastName: response.data.lname,
						fullName: response.data.fname + ' ' + response.data.lname,
						cover: response.data.cover,
						pictures: book && book.pages.map((page => {
							return page.picture.url;
						})),
						digitals: digitals && digitals.pages.map((page => {
							return page.picture.url;
						})),
						videos: response.data.vbooks.length && response.data.vbooks[0].pages.map((page => {
							return {
								url: page.url,
								thumbnail: page.thumbnail,
							};
						})),
						features: response.data.features && response.data.features.filter((feature: IFeature) => !feature.name.toLowerCase().includes('instagram')),
						socials: response.data.socials
					};
				} else {
					// @ts-ignore
					this.error = 'Model not found';
				}
        this.isFetching = false
      });
    } catch (error) {
      this.error = error;
      this.isFetching = false
    }
  }

  @action.bound
  async fetchModels(group: number = -1, key: string = '') {
    this.isFetching = true;
    this.error = null;

    try {
      let qs = group > -1 ? `&gr=${group}` : ``;
      qs += key.trim().length > 0 ? `&src=${key}` : '';

      if(!qs){
        return false;
      }

      const endpoint = `${LOCAL_API_BASE_URL}/api/models?d=1${qs}`;

      const response = await axios.get(endpoint);

      this.models = response.data.map((item => {
        return {
          id: item.id,
          firstName: item.fname,
          lastName: item.lname,
          fullName: item.fname + ' ' + item.lname,
          cover: item.cover,
          features: item.features && item.features.filter((feature: IFeature) => !feature.name.toLowerCase().includes('instagram')),
          socials: item.socials,
        }
      }));
      this.isFetching = false
    } catch (error) {
      this.error = error;
      this.isFetching = false
    }
  }

  @action.bound
  async fetchMainModels() {
    this.isFetching = true;
    this.error = null;

    try {
      let endpoint = `${LOCAL_API_BASE_URL}/api/ticker-data`;
      const response = await axios.get(endpoint);

      this.mainModels = response.data && Array.isArray(response.data) ? response.data : [];
      this.isFetching = false
    } catch (error) {
      this.error = error;
      this.isFetching = false
    }
  }

  @action
  resetJoin() {
    this.joined = false;
  }

  @action
  join(data: IJoinData) {
    this.isJoining = true;
    this.joinError = null;
    this.joined = false;


    if(constants.DISABLE_CONSENT_SOLUTION) {
			const endpoint = `${LOCAL_API_BASE_URL}/api/join`;

			axios({
				method: 'POST',
				url: endpoint,
				data
			}).then((response)=> {
				this.isJoining = false;
				const { data: { statusCode, message } } = response;

				if(statusCode !== 200) {
					this.joinError = message
				} else {
					this.joined = true;
				}
			})
		} else {

			const csData = {
				"subject": {
					"email": data.email,
					"first_name": data.firstName,
					"last_name": data.lastName,
					"full_name": data.firstName + ' ' + data.lastName,
					"verified": false
				},
				"legal_notices": [
					{
						"identifier": "privacy_policy"
					},
				],
				"preferences": {
					"newsletter": data.newsletter,
					"privacy_policy": data.privacy
				},
				"autodetect_ip_address": true
			};

			axios({
				method: 'POST',
				url: 'https://consent.iubenda.com/consent/',
				headers: {
					'ApiKey': process.env.REACT_APP_IUBENDA_API_KEY,
					'Content-Type': 'application/json'
				},
				data: csData
			}).then((response)=> {
				const { status, statusText } = response;

				if(status === 200){
					const endpoint = `${LOCAL_API_BASE_URL}/api/join`;

					axios({
						method: 'POST',
						url: endpoint,
						data
					}).then((response)=> {
						this.isJoining = false;
						const { data: { statusCode, message } } = response;

						if(statusCode !== 200) {
							this.joinError = message
						} else {
							this.joined = true;

							// Mailchimp
							/*if(data.newsletter){
								const mcEndpoint = `${LOCAL_API_BASE_URL}/api/newsletter`;

								const mcData = {
									email_address: data.email,
									status: "subscribed",
									merge_fields: {
										FNAME: data.firstName,
										LNAME: data.lastName
									}
								};

								axios({
									method: 'POST',
									url: mcEndpoint,
									data: mcData,
								}).then((response)=> {
									const { data } = response;

									if(data.hasOwnProperty('status') && data.status !== 'subscribed'){
										console.error(data.detail)
									}
								})
							}*/
						}
					});
				} else {
					// @ts-ignore
					this.joinError = statusText;
				}
			}).catch((error) => {
				this.isJoining = false;
				const { message } = error;
				// @ts-ignore
				this.joinError = `IUBENDA Consent Solution: ${message}`;
			});
		}
  }

  @computed
  get modelsCount() {
    return this.models.length;
  }
}
