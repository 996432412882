import { observable, action } from 'mobx';
import axios from 'axios';
import { IInstagramStore } from '../shared/interfaces';

const LOCAL_API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ''}${process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

export class InstagramStore implements IInstagramStore {
  @observable myProfile = null;
  @observable myFeed = [];
  @observable currentProfile = null;
  @observable currentFeed = [];
  @observable error = null;
  @observable isFetching = false;

  @action
  async fetchMyProfile() {
    this.isFetching = true;
    this.error = null;
    try {
      const endpoint = `https://api.instagram.com/v1/users/self/?access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN}`;
      axios.get(endpoint).then(response => {
        this.myProfile = response.data.data;
        this.isFetching = false
      });

      this.isFetching = false
    } catch (error) {
      this.error = error;
      this.isFetching = false
    }
  }

  @action.bound
  fetchMyFeed() {
    this.isFetching = true;
    this.error = null;
    try {
      const endpoint = `https://api.instagram.com/v1/users/self/media/recent?access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN}`;
      axios.get(endpoint).then(response => {
        this.myFeed = response.data.data.map(item => {
          return {
            id: item.id,
            caption: item.caption.text,
            image: item.images.standard_resolution.url,
            link: item.link,
            date: item.created_time
          }
        });

        this.isFetching = false
      })
    } catch (error) {
      this.error = error;
      this.isFetching = false
    }
  }

  // For easy usage
  @action.bound
  fetchProfileByToken(token?: string) {
    this.error = null;
    this.currentProfile = null;

    if(token){
      this.isFetching = true;

      try {
        const endpoint = `https://api.instagram.com/v1/users/self/?access_token=${token}`;
        axios.get(endpoint).then(response => {
          this.currentProfile = response.data.data;
          this.isFetching = false
        }).catch((error) => {
          this.error = error;

          this.isFetching = false
        })
      } catch (error) {
        this.error = error;
        this.isFetching = false
      }
    }
  }

  @action.bound
  fetchFeedByToken(token?: string) {
    this.error = null;
    this.currentFeed = [];

    if(token){
      this.isFetching = true;

      try {
        const endpoint = `https://api.instagram.com/v1/users/self/media/recent?access_token=${token}`;
        axios.get(endpoint).then(response => {
          this.currentFeed = response.data.data.map(item => {
            //console.log(item);
            return {
              id: item.id,
              caption: item.caption ? item.caption.text : '',
              image: item.images.standard_resolution.url,
              link: item.link,
              date: item.created_time
            }
          });

          this.isFetching = false
        }).catch((error) => {
          this.error = error;

          this.isFetching = false
        })
      } catch (error) {
        this.error = error;
        this.isFetching = false
      }}
  }

  @action.bound
  fetchInstagramByModelId(id: number) {
    this.isFetching = true;
    this.error = null;

    try {
      const endpoint = `${LOCAL_API_BASE_URL}/api/token/${id}`;

      axios.get(endpoint).then(response => {
        if(response.data){
          const { token } = response.data;

          if(token) {
            this.fetchFeedByToken(token);
            this.fetchProfileByToken(token);
          } else {
            this.currentProfile = null;
            this.currentFeed = [];
          }
        } else {
          // @ts-ignore
          this.error = 'Model not found';
        }
        this.isFetching = false
      });
    } catch (error) {
      this.error = error;
      this.isFetching = false
    }
  }
}
