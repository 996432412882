import React, { Component, createRef } from 'react';
import classes from './styles.module.scss';

interface IProps {
  images: Array<string>;
  id: number;
  imageClicked: (index: number) => void;
  alignment?: 'center' | 'normal';
  size?: 'large';
}

export default class Mosaic extends Component<IProps> {
  mosaic = createRef<HTMLDivElement>();

  render(){
    const { images, imageClicked, alignment, size } = this.props;

    const mosaicTpl = images.map((image: string, index: number) => {
      return (
        <button
          className={[
          	classes.Thumbnail,
						size ? classes[`Thumbnail--${size}`] : ''
          ].join(' ')}
          onClick={() => imageClicked(index)}
          key={index}>
          <img
            className={classes['Thumbnail-image']}
            src={image}
            alt={'thumbnail'}/>
        </button>
      )
    });

    return (
      <div
        ref={this.mosaic}
        className={[
          classes.Mosaic,
          alignment ? classes[`Mosaic--${alignment}`] : ''
        ].join(' ')}>
        <div className={classes['Mosaic-content']}>
        { mosaicTpl }
        </div>
      </div>
    )
  }
}
