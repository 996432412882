import React, { Component } from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';

class ThankYou extends Component {
	render() {
		return (
			<div className={classes.ThankYou}>
				<h1 className={classes['ThankYou-title']}><FormattedMessage id={'thankYou'} /></h1>
			</div>
		);
	}
}

export default ThankYou;
