import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import * as routes from '../../shared/routes';
import { History } from 'history';
import constants from '../../shared/constants';
import { IModelStore } from '../../shared/interfaces';
import TalentList from '../TalentList';
import Footer from '../Footer';
import Header from '../Header';
import classes from './styles.module.scss';

interface IProps {
	modelStore: IModelStore;
	history: History;
}

interface IState {
	searchKey: string;
}

@inject('modelStore')
@observer
class Talent extends Component<IProps, IState> {
	state = {
		searchKey: '',
	};

	componentDidMount(): void {
		window.scrollTo(0, 0);
		this.searchByKey('')
	}

	getModels = (searchKey: string = '') => {
		const { modelStore: { fetchModels } } = this.props;

		fetchModels(13, searchKey);
	};

	searchByKey = (key: string) => {
		this.getModels(key);
	};

	render() {
		const { searchKey } = this.state;

		return (
			<div className={classes.Woman}>
        <Header
					history={this.props.history}
					hasSearch={true}
					searchChanged={(searchKey: string) => this.searchByKey(searchKey)}
					searchValue={searchKey}/>
				<TalentList
					searchKey={searchKey}
					modelStore={this.props.modelStore} />
				<Footer />
			</div>
		);
	}
}

export default Talent;
