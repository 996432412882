import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { History } from 'history';
import { IFeed, IInstagramStore, IModelStore } from '../../shared/interfaces';
import Footer from '../Footer';
import Header from '../Header';
import InstagramContainer from '../InstagramContainer';
import ModelCover from '../ModelCover';
import classes from './styles.module.scss';
import { Redirect } from 'react-router';
import * as routes from '../../shared/routes';

interface IProps {
	history: History;
}

interface IProps {
	modelStore: IModelStore;
	instagramStore: IInstagramStore;
	match: {
		params: {
			id: string;
		};
	};
}

interface IState {
	id: string;
	data: any;
}

@inject('modelStore')
@inject('instagramStore')
@observer
export default class ModelDetails extends Component<IProps, IState> {
	state = {
		id: "",
		data: null,
	};

	componentDidMount(): void {
		window.scrollTo(0, 0);

		const { modelStore: { fetchModel }, match: { params: { id } }, instagramStore: { fetchInstagramByModelId } } = this.props;

		if(id && fetchModel){
			const ids = id.split("-");
			fetchModel(+ids[ids.length - 1]);
			fetchInstagramByModelId(+ids[ids.length - 1]);
		}
	}

	render() {
		const { modelStore, instagramStore } = this.props;

		if(modelStore.error === 'Model not found'){
			return <Redirect to={routes.WOMEN} />;
		}

		const bio = modelStore.currentModel?.features.find(e => e.name === 'bio')
		return (
			<div className={classes.ModelDetails}>
        <Header
					wide={true}
					history={this.props.history}
					overlay={true}
					light={true} />
				<ModelCover
					instagramStore={instagramStore}
					modelStore={modelStore} />
					{
						instagramStore.currentProfile &&
						<InstagramContainer
							followers={instagramStore.currentProfile.counts.followed_by}
							images={instagramStore.currentFeed.map((item: IFeed) => item.image)}
							instagram={{
								type: "instagram",
								username: instagramStore.currentProfile.username,
								link: `https://www.instagram.com/${instagramStore.currentProfile.username}`,
								followers: ''
							}}/>
					}
					{
						bio && <p className={classes.ModelBio}>{bio.value}</p>
					}
				<Footer />
			</div>
		);
	}
}
