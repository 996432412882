import axios from 'axios';
import React, { Component } from 'react';
import { IFeature, IModel } from '../../shared/interfaces';
import classes from './styles.module.scss';
import { Page, View, Document, StyleSheet, Image, PDFDownloadLink, Text, Font } from '@react-pdf/renderer';
import downloadIcon from '../../assets/icons/download.svg';
import logo from '../../assets/images/export/fabbrica-milano.png';

//@ts-ignore
import ivyModeLight from '../../assets/fonts/IvyMode-Light.ttf';
//@ts-ignore
import usualRegular from '../../assets/fonts/Usual-Regular.ttf';
//@ts-ignore
import usualBold from '../../assets/fonts/Usual-Bold.ttf';

const LOCAL_API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ''}${process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

interface IProps {
	model: IModel;
	instagramUser?: string;
	fileName: string;
	label: object;
}
interface IDocProps {
	model: IModel;
	instagramUser?: string;
	fileName: string;
	label: object;
	images64: Array<string>;
}

interface IState {
	ready: boolean;
}

// Register font
Font.register({ family: 'IvyMode-Light', src: ivyModeLight });
Font.register({ family: 'Usual-Regular', src: usualRegular });
Font.register({ family: 'Usual-Bold', src: usualBold });

// Create styles
const styles = StyleSheet.create({
	title: {
		marginTop: 60,
		fontFamily: 'IvyMode-Light',
		letterSpacing: 3,
		marginLeft: 40,
		fontSize: 30,
		marginBottom: 5,
		textTransform: 'uppercase'
	},
	subtitle: {
		fontFamily: 'Usual-Bold',
		letterSpacing: 1,
		marginLeft: 40,
		fontSize: 8,
		marginBottom: 40,
		textTransform: 'uppercase'
	},
	// Content
	contentPage: {
		display: "flex",
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between'
	},
	section: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignContent: "center",
		alignItems: "center",
		flexWrap: 'wrap',
		maxWidth: 400
	},
	image: {
		width: 200,
		height: 'auto',
	},
	features: {
		marginLeft: 40,
	},
	feature: {
		marginBottom: 5,
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
	},
	featureName: {
		textTransform: 'uppercase',
		marginRight: 10,
		fontSize: 8,
		fontFamily: 'Usual-Regular',
		width: 30,
	},
	featureValue: {
		textTransform: 'uppercase',
		fontSize: 8,
		fontFamily: 'Usual-Bold'
	},
	link: {
		textTransform: 'uppercase',
		marginTop: 15,
		fontSize: 8,
		fontFamily: 'Usual-Bold',
		letterSpacing: 1,
		marginBottom: 0
	},
	logo: {
		width: 110,
		height: 'auto',
		position: 'absolute',
		right: 40,
		bottom: 40
	}
});

const Doc = (props: IDocProps) => {
	let index = 0;

	const featuresTpl = (
		<View style={styles.features}>
			{
				props.model.features.filter(item => item.name !== 'instagram account' && item.name !== 'bio').map((item: IFeature, index: number) =>{
					return (
						<View style={styles.feature} key={index}>
							<Text style={styles.featureName}>{ item.name }</Text>
							<Text style={styles.featureValue}>{ item.value }</Text>
						</View>
					)
				})
			}
			<Text style={styles.link}>{ 'fabbricamilano.com' }</Text>
		</View>
	);

	return (
		<Document>
			<Page orientation={'portrait'} key={index} size="A4">
				<Text style={styles.title}>{ props.model.fullName }</Text>
				<Text style={styles.subtitle}>{ props.instagramUser ? `@${props.instagramUser}` : '' }</Text>
				<View style={styles.contentPage}>
					{
						featuresTpl
					}
					<View style={styles.section}>
						<Image
							style={styles.image}
							src={props.images64[0]}/>
						<Image
							style={styles.image}
							src={props.images64[1]}/>
						<Image
							style={styles.image}
							src={props.images64[2]}/>
						<Image
							style={styles.image}
							src={props.images64[3]}/>
					</View>
				</View>
				<Image style={styles.logo} src={logo} />
			</Page>
		</Document>
	)
};

class PDFDigitalsButton extends Component<IProps, IState> {
	state = {
		ready: false,
		images: [],
	};

	createBase64Image = async (image) => {
		const img = `${LOCAL_API_BASE_URL}/api/proxy-image/${encodeURIComponent(image)}`;

		let response = await axios.get(img);

		let { data } = response;

		return data;
	};

	componentDidMount(): void {
		const { model } = this.props;

		const curr = model.digitals.map(async (image: string) => {
			return await this.createBase64Image(image).then(result => result);
		});

		Promise.all(curr).then((values: Array<string>) => {
			this.setState({
				// @ts-ignore
				images: values,
				ready: true,
			})
		});
	}

	render() {
		const { label, fileName } = this.props;

		return  this.state.ready ?
			<PDFDownloadLink
				fileName={fileName}
				className={classes.PDFDigitalButton}
				document={<Doc images64={this.state.images} {...this.props}/>}>
				<span>{ label }</span>
				<img src={downloadIcon} alt={'download'} />
			</PDFDownloadLink> : ''
	}
}

export default PDFDigitalsButton;
