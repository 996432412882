import React, { Component } from 'react';
import { ISocial } from '../../shared/interfaces';
import InstagramImage from '../InstagramImage';
import classes from './styles.module.scss';

interface IProps {
	instagram: ISocial;
	followers: number;
	images: Array<string>;
}

export default class InstagramContainer extends Component<IProps> {
	render() {
		const { instagram, images, followers } = this.props;

		const imagesTpl = images.slice(0, 3).map((image: string, index: number) => <InstagramImage key={index} image={image} /> );

		const followersTpl = followers === 1 ? followers.toString() + ' follower' : followers.toString() + ' followers';

		return (
			<div className={classes.InstagramContainer}>
				<div className={classes['InstagramContainer-header']}>
					<a className={classes['InstagramContainer-username']} href={instagram.link} target={'_blank'}>{ `@${instagram.username}` }</a>
					<span className={classes['InstagramContainer-followers']}>{ followersTpl }</span>
				</div>
				<div className={classes['InstagramContainer-content']}>
					{
						imagesTpl
					}
				</div>
			</div>
		);
	}
}
