import React, { Component } from 'react';
import classes from './styles.module.scss';

interface IProps {
	image: string;
}

class InstagramImage extends Component<IProps> {
	render () {
		const {
			image,
		} = this.props;

		const backgroundImage = {
			backgroundImage: image ? `url(${image})` : 'none'
		};

		return (
			<div className={classes['InstagramImage']} style={backgroundImage} />
		);
	}
}

export default InstagramImage;
