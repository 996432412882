export default {
  LOCAL_STORAGE: {
    KEYS: {
      USER_ID: 'ac_user_id',
      AUTH_TOKEN: 'ac_auth_token',
    }
  },
  TALENT_GROUPS: [
    {
      key: 'image',
      id: 8,
    },
    {
      key: 'development',
      id: 10,
    },
    {
      key: 'main',
      id: 9,
    },
    {
      key: 'shows',
      id: 7,
    }
  ],
  WOMEN_GROUPS: [
    {
      key: 'image',
      id: 8,
    },
    {
      key: 'management',
      id: 23,
    },
    {
      key: 'development',
      id: 22,
    },
    {
      key: 'main',
      id: 15,
    },

  ],
  NEWS_PADDED_INDEXES: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46, 49, 52, 55, 58, 61, 64],
  INSTAGRAM: {
    link: 'https://www.instagram.com/fabbrica.milano/',
    username: 'fabbrica.milano'
  },
  PRIVACY_POLICY_LINK: 'https://www.iubenda.com/privacy-policy/98590264/full-legal',
  COOKIE_POLICY_LINK: 'https://www.iubenda.com/privacy-policy/98590264/cookie-policy',
  TERMS_AND_CONDITIONS_POLICY_LINK: 'https://www.iubenda.com/terms-and-conditions/98590264',
  DISABLE_SCOUTING: false,
  DISABLE_WOMEN: false,
  DISABLE_TALENT: true,
  DISABLE_EXPORT_PDF: false,
	DISABLE_CONSENT_SOLUTION: false,
}
