import axios from 'axios';
import React, { Component } from 'react';
import { IFeature, IModel } from '../../shared/interfaces';
import classes from './styles.module.scss';
import { Page, View, Document, StyleSheet, Image, PDFDownloadLink, Text, Font } from '@react-pdf/renderer';
import downloadIcon from '../../assets/icons/download.svg';
import logo from '../../assets/images/export/fabbrica-milano.png';
import triangles from '../../assets/images/export/triangles.png';

//@ts-ignore
import ivyModeLight from '../../assets/fonts/IvyMode-Light.ttf';
//@ts-ignore
import usualRegular from '../../assets/fonts/Usual-Regular.ttf';
//@ts-ignore
import usualBold from '../../assets/fonts/Usual-Bold.ttf';

const LOCAL_API_BASE_URL = `${process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ''}${process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}`;

interface IProps {
	model: IModel;
	fileName: string;
	label: object;
}
interface IDocProps {
	model: IModel;
	fileName: string;
	label: object;
	images64: Array<string>;
}

interface IState {
	ready: boolean;
}

// Register font
Font.register({ family: 'IvyMode-Light', src: ivyModeLight });
Font.register({ family: 'Usual-Regular', src: usualRegular });
Font.register({ family: 'Usual-Bold', src: usualBold });

// Create styles
const styles = StyleSheet.create({
	// First page
	headerPage: {
		padding: '45% 10% 6%',
		display: 'flex',
		//flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'nowrap'
	},
	mainLogo: {
		width: 260,
		height: 'auto'
	},
	title: {
		margin: 'auto auto 0',
		fontFamily: 'IvyMode-Light',
		letterSpacing: 2
	},
	// Content
	contentPage: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
		alignContent: "center",
		flexWrap: "nowrap",
		alignItems: "center",
		padding: '10% 0 0'
	},
	section: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignContent: "center",
		flexWrap: "nowrap",
		alignItems: "center"
	},
	image: {
		width: 320,
		height: 'auto'
	},
	features: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		flexWrap: "nowrap",
		alignItems: "center",
		width: '100%',
		paddingLeft: 60,
		paddingRight: 60,
		marginTop: 'auto',
		marginBottom: 40,
	},
	feature: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
	},
	featureName: {
		textTransform: 'uppercase',
		marginRight: 10,
		fontSize: 8,
		fontFamily: 'Usual-Regular'
	},
	featureValue: {
		textTransform: 'uppercase',
		marginRight: 15,
		fontSize: 8,
		fontFamily: 'Usual-Bold'
	},
	modelName: {
		textTransform: 'uppercase',
		fontSize: 8,
		marginRight: 15,
		fontFamily: 'Usual-Bold'
	},
	triangles: {
		height: 12,
		width: 'auto',
		marginLeft: 'auto'
	},
	// Last Page
	footerPage: {
		padding: '44% 10% 7%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	contacts: {
		fontSize: 8,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
		alignContent: "center",
		flexWrap: "nowrap",
		alignItems: "center",
		fontFamily: 'Usual-Regular'
	},
	span: {
		fontSize: 9,
		marginBottom: 5,
	},
	email: {
		fontSize: 9,
		marginTop: 13,
		marginBottom: 15
	},
	phone: {
		fontSize: 9
	},
	secondaryLogo: {
		width: 120,
		height: 'auto'
	}
});

const Doc = (props: IDocProps) => {
	let pagesTpl: Array<any> = [];
	let index = 0;

	const featuresTpl = (
		<View style={styles.features}>
			<View style={styles.feature} key={index}>
				<Text style={styles.modelName}>{ props.model.fullName }</Text>
			</View>
			{
				props.model.features.filter(item => item.name !== 'instagram account' && item.name !== 'bio').map((item: IFeature, index: number) =>{
					return (
						<View style={styles.feature} key={index}>
							<Text style={styles.featureName}>{ item.name }</Text>
							<Text style={styles.featureValue}>{ item.value }</Text>
						</View>
					)
				})
			}
			<Image style={styles.triangles} src={triangles} />
		</View>
	);

	do {
		if(index + 1 < props.images64.length && index > 0) {
			pagesTpl.push(
				<Page orientation={'landscape'} key={index} size="A4" style={styles.contentPage}>
					<View style={styles.section}>
						<Image
							style={styles.image}
							src={props.images64[index]}/>
						<Image
							style={styles.image}
							src={props.images64[index + 1]}/>
					</View>
					{
						featuresTpl
					}
				</Page>);
			index = index + 2;
		}
		else {
			pagesTpl.push(
				<Page key={index} orientation={'landscape'} size="A4" style={styles.contentPage}>
					<View style={styles.section}>
						<Image
							style={styles.image}
							src={props.images64[index]}/>
					</View>
					{
						featuresTpl
					}
				</Page>);
			index++;
		}
	} while (index < props.images64.length);

	return (
		<Document>
			<Page
				orientation={'landscape'}
				size="A4"
				style={styles.headerPage}>
				<Image style={styles.mainLogo} src={logo} />
				<Text style={styles.title}>{ props.model.fullName }</Text>
			</Page>
			{
				pagesTpl
			}
			<Page
				orientation={'landscape'}
				size="A4"
				style={styles.footerPage}>
				<View style={styles.contacts}>
					<Text style={styles.span}>{ 'VIA NIRONE, 2' }</Text>
					<Text style={styles.span}>{ '20123 MILANO - ITALIA' }</Text>
					<Text style={styles.email}>{ 'INFO@FABBRICAMILANO.COM' }</Text>
					<Text style={styles.phone}>{ '+39 02 4210 7213' }</Text>
				</View>
				<Image style={styles.secondaryLogo} src={logo} />
			</Page>
		</Document>
	)
};

class PDFPortfolioButton extends Component<IProps, IState> {
	state = {
		ready: false,
		images: [],
	};

	createBase64Image = async (image) => {
		const img = `${LOCAL_API_BASE_URL}/api/proxy-image/${encodeURIComponent(image)}`;

		let response = await axios.get(img);

		let { data } = response;

		return data;
	};

	componentDidMount(): void {
		const { model } = this.props;

		const curr = model.pictures.map(async (image: string) => {
			return await this.createBase64Image(image).then(result => result);
		});

		Promise.all(curr).then((values: Array<string>) => {
			this.setState({
				// @ts-ignore
				images: values,
				ready: true,
			})
		});
	}

	render() {
		const { label, fileName } = this.props;

		return  this.state.ready ?
			<PDFDownloadLink
				fileName={fileName}
				className={classes.PDFPortfolioButton}
				document={<Doc images64={this.state.images} {...this.props}/>}>
				<span>{ label }</span>
				<img src={downloadIcon} alt={'download'} />
			</PDFDownloadLink> : ''
	}
}

export default PDFPortfolioButton;
