import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { IModelStore } from '../../shared/interfaces';
import CoverGallery from '../CoverGallery';
import { History } from 'history';
import Header from '../Header';
import List from '../List';
import classes from './styles.module.scss';

interface IProps {
	modelStore: IModelStore;
	history: History;
}

@inject('modelStore')
@observer
export default class Home extends Component<IProps> {

	render() {
		const { modelStore, history } = this.props;

		return (
			<div className={classes.Home}>
        <Header
					wide={true}
					history={this.props.history}
					center={true}
					hideLogo={true} />
        <div
          className={classes['Home-wrapper']}>
					<CoverGallery />
					<List
						modelStore={modelStore}
						history={history}/>
        </div>
			</div>
		);
	}
}
