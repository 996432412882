import React, { Component } from 'react';
import classes from './styles.module.scss';

interface IProps {
	image: string;
	date?: number;
	caption?: string;
	padded?: boolean;
	clicked?: () => void;
	full?: boolean;
	to?: string;
}

class Picture extends Component<IProps> {
	render () {
		const {
			image,
			clicked,
			full,
			to
		} = this.props;

		let tpl = (
			<div
				style={{
					backgroundImage: `url(${image})`
				}}
				onClick={to ? undefined : clicked}
				className={[
					classes.Picture,
					full ? classes['Picture--full'] : ''
				].join(' ')} />
		);

		if(to){
			tpl = (
				<a href={to} target={'_blank'}>
					{
						tpl
					}
				</a>
			);
		}

		return tpl;
	}
}

export default Picture;
