import './polyfills';
import React from 'react';
import { render } from 'react-snapshot';
import './normalize.scss';
import './index.scss';
import App from './App';
import { stores } from './stores';
import {Provider} from 'mobx-react';

import * as serviceWorker from './serviceWorker';

const root = (
  <Provider {...stores}>
    <App />
  </Provider>
);

render(
  root,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
