import moment from 'moment';

export const getViewPortSize = () => {
  if (typeof window !== 'undefined') {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  return null;
};



export const checkValidEmail = (email: string) => {
  if (!email || email.trim() === '') {
    return false;
  }

  const regExp =
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

  const matcher = new RegExp(regExp);
  const keywordTest = email.trim();

  return matcher.test(keywordTest);
};

export const checkValidPhone = (phone: string) => {
  let valid = false;

  if (phone.length > 20) {
    valid = false;
  } else {
    if (phone.trim() !== '') {
      const regExp = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$';
      const matcher = new RegExp(regExp);

      const keywordTest = phone.trim();

      valid = matcher.test(keywordTest);
    } else {
      valid = false;
    }
  }

  return valid;
};

export const getTimeDiff = (timestamp: number) => {
  if(!timestamp){
    return '';
  }

  // diff
  let unit = ' h';
  let diff = moment().diff(moment.unix(timestamp), 'hours');

  if(diff === 0){
    diff = moment().diff(moment.unix(timestamp), 'minutes');
    unit = ' m';

    if(diff === 0){
      diff = moment().diff(moment.unix(timestamp), 'seconds');
      unit = ' s';
    }
  } else {
    if(diff > 23) {
      diff = moment().diff(moment.unix(timestamp), 'days');
      unit = ' d';
    }
  }

  return diff + unit;
};
