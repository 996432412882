import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { NAVIGATION } from '../../shared/data';
import { INav } from '../../shared/interfaces';
import classes from './styles.module.scss';

interface IProps {
	styles?: object;
}

class Navigation extends Component<IProps> {
	render () {
		const navTpl = NAVIGATION.filter((_nav: INav) => _nav.visible).map((nav: INav, index: number) => {
			return nav.key !== 'shop'
				? <NavLink key={index} className={classes['Navigation-item']} to={nav.route}>{ nav.label }</NavLink>
				: <a 
					href={nav.route} 
					target="_blank"
					className={classes['Navigation-item']}
				>
					{ nav.label }
				</a>
		});

		return (
			<div className={classes.Navigation} style={this.props.styles}>
				{navTpl}
			</div>
		);
	}
}

export default Navigation;
