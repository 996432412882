import React, { Component } from 'react';
import { IPrivacyPolicyParagraph } from '../../shared/interfaces';
import Footer from '../Footer';
import Header from '../Header';
import classes from './styles.module.scss';
import Label from '../Label';
import { History } from 'history';
import { PRIVACY_POLICY_PARAGRAPHS } from '../../shared/data';

interface IProps {ì
	history: History;
}

class PrivacyPolicy extends Component<IProps> {
	componentDidMount(): void {
		window.scrollTo(0, 0);
	}

	render() {

		const privacyTpl = PRIVACY_POLICY_PARAGRAPHS.map((pr: IPrivacyPolicyParagraph) => {
			return (
				<div className={classes['PrivacyPolicy-paragraph']}>
					<Label>
						{ pr.title }
					</Label>
					<div>
						{
							pr.content
						}
					</div>
				</div>
			)
		});

		return (
			<div className={classes.PrivacyPolicy}>
				<Header
					history={this.props.history}/>
				<div className={classes['PrivacyPolicy-content']}>
				{
					privacyTpl
				}
				</div>
				<Footer />
			</div>
		);
	}
}

export default PrivacyPolicy;
