import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import * as routes from '../../shared/routes';
import { History } from 'history';
import constants from '../../shared/constants';
import { IModelStore } from '../../shared/interfaces';
import WomanFilters from '../WomanFilters';
import WomanList from '../WomanList';
import Footer from '../Footer';
import Header from '../Header';
import classes from './styles.module.scss';

interface IProps {
	modelStore: IModelStore;
	history: History;
	match: {
		params: {
			group: string;
		};
	};
}

interface IState {
	currentGroup: number;
	searchKey: string;
}

@inject('modelStore')
@observer
class Woman extends Component<IProps, IState> {
	state = {
		currentGroup: -1,
		searchKey: '',
	};

	componentDidMount(): void {
		window.scrollTo(0, 0);
		this.checkGroupParam()
	}

	checkGroupParam = () => {
		const { match: { params: { group } } } = this.props;

		let groupKey = group ? group : 'image';
		this.findGroupByKey(groupKey);
	};

	findGroupByKey = (group: string) => {
		const currentGroup = constants.WOMEN_GROUPS.find((item: { id: number, key: string }) => item.key === group);

		if(currentGroup){
			this.setState({
				currentGroup: currentGroup.id
			}, () => {
				this.getModels(currentGroup.id)
			})
		} else {
			this.props.history.push(routes.WOMEN.replace(':group', 'image'))
		}
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.match !== prevProps.match){
			this.checkGroupParam()
		}
	}

	getModels = (currentGroup: number = -1, searchKey: string = '') => {
		const { modelStore: { fetchModels } } = this.props;

		//console.log('[Woman] Search for group / key:', currentGroup, searchKey);

		fetchModels(currentGroup, searchKey);
	};

	searchByGroup = (group: number) => {
		this.setState({
			currentGroup: group,
			searchKey: '',
		}, () => {
			this.getModels(group, '');
		})
	};

	searchByKey = (key: string) => {
		if(key.trim().length > 2){
			this.setState({
				currentGroup: -1,
				searchKey: key
			}, () => {
				this.getModels(-1, key);
			})
		}
	};

	render() {
		const { currentGroup, searchKey } = this.state;

		return (
			<div className={classes.Woman}>
        <Header
					history={this.props.history}
					hasSearch={true}
					searchChanged={(searchKey: string) => this.searchByKey(searchKey)}
					searchValue={searchKey}/>
        <WomanFilters
					currentGroup={currentGroup}
					filterClicked={(currentGroup) => this.searchByGroup(currentGroup)} />
				<WomanList
					searchKey={searchKey}
					modelStore={this.props.modelStore} />
				<Footer />
			</div>
		);
	}
}

export default Woman;
