import React, { Component } from 'react';
import { IFeature } from '../../shared/interfaces';
import classes from './styles.module.scss';

interface IProps {
	styles?: object;
	items: Array<IFeature>;
}

class Features extends Component<IProps> {
	render () {
		const { items, styles } = this.props;

		const featuresTpl = items.map((item: IFeature, index: number) => {
			return item.name !== 'bio' && item.value ? (
				<li className={classes['Features-item']} key={index}>
					<span>{ item.name }</span>
					<strong>{ item.value }</strong>
				</li>
			) : ''
		});

		return (
			<ul className={classes.Features} style={styles}>
				{featuresTpl}
			</ul>
		);
	}
}

export default Features;
