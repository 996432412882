import React, { Component } from 'react';
import ContactList from '../ContactList';
import { History } from 'history';
import Footer from '../Footer';
import Header from '../Header';
import classes from './styles.module.scss';

interface IProps {
	history: History;
}

class Contact extends Component<IProps> {
	componentDidMount(): void {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<div className={classes.Contact}>
        <Header
					history={this.props.history}/>
				<ContactList />
				<Footer />
			</div>
		);
	}
}

export default Contact;
