import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IFormInput, IModelStore } from '../../shared/interfaces';
import ScoutingForm from '../ScoutingForm';
import classes from './styles.module.scss';
import { History } from 'history';
import Footer from '../Footer';
import Header from '../Header';

interface IProps {
	history: History;
	modelStore: IModelStore;
}

interface IState {
	form: Array<IFormInput>,
	sending: boolean;
	feedback: string;
	files: Array<File | undefined>;
	previews: Array<string | ArrayBuffer | null>
}

@inject('modelStore')
@observer
class Scouting extends Component<IProps, IState> {

	componentDidMount(): void {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<div className={classes.Scouting}>
        <Header
					history={this.props.history}/>
        <ScoutingForm
					modelStore={this.props.modelStore}/>
				<Footer />
			</div>
		);
	}
}

export default Scouting;
