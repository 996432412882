import React, {Component, Fragment} from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import {
	disableBodyScroll,
	clearAllBodyScrollLocks
} from 'body-scroll-lock';
import { FormattedMessage } from 'react-intl';
import constants from '../../shared/constants';
import { IInstagramStore, IModelNav, IModelStore } from '../../shared/interfaces';
import Button from '../Button';
import ModelGallery from '../ModelGallery';
import ModelNavigation from '../ModelNavigation';
import Loader from '../Loader';
import ModelVideo from '../ModelVideo';
import PDFPortfolioButton from '../PDFPortfolioButton';
import classes from './styles.module.scss';

import triangle from '../../assets/images/triangles/animated-triangles--black.gif';
import thumbnailsIcon from '../../assets/icons/thumbnails.svg';
import PDFDigitalButton from "../PDFDigitalsButton";
import {getViewPortSize} from "../../shared/helpers";

interface IProps {
	modelStore: IModelStore;
	instagramStore: IInstagramStore;
	show: boolean;
	closeClicked: () => void;
	navItems: Array<IModelNav>;
	currentNavId: number;
}

interface IState {
	showPortfolioThumbnails: boolean;
	showDigitalsThumbnails: boolean;
	showInstagramThumbnails: boolean;
	visible: boolean;
	isDesktop: boolean;
	media: {
		portfolio: {
			pictures: Array<string>;
		};
		digitals: {
			pictures: Array<string>;
		};
		instagram: {
			pictures: Array<string>;
			links: Array<string>;
		};
	}
}

@inject('modelStore')
@inject('instagramStore')
@observer
class ModelLightBox extends Component<IProps, IState> {
	targetRef = React.createRef<HTMLDivElement>();
	targetElement;

	state = {
		showPortfolioThumbnails: false,
		showDigitalsThumbnails: false,
		showInstagramThumbnails: true,
		visible: false,
		isDesktop: false,
		media: {
			portfolio: {
				pictures: []
			},
			digitals: {
				pictures: []
			},
			instagram: {
				pictures: [],
				links: []
			},
		}
	};

	componentDidMount(): void {
		this.targetElement = this.targetRef.current;
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
		this.populateMedia();

		if(this.targetElement){
			this.setState({
				visible: this.props.show,
			}, () => {
				disableBodyScroll(this.targetElement);
			})
		}
	}

	populateMedia = () => {
		const { modelStore, instagramStore } = this.props;

		let images: Array<string> = [];
		let links: Array<string> = [];

		if(instagramStore.currentFeed.length > 0){
			images = instagramStore.currentFeed.map((item: { image }) => item.image);
			links = instagramStore.currentFeed.map((item: { link }) => item.link);
		}

		let media = {
			portfolio: {
				pictures: modelStore.currentModel && modelStore.currentModel.pictures ? modelStore.currentModel.pictures : []
			},
			digitals: {
				pictures: modelStore.currentModel && modelStore.currentModel.digitals ? modelStore.currentModel.digitals : []
			},
			instagram: {
				pictures: images || [],
				links: links || []
			},
		};

		this.setState({
			media
		})
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if(this.props.currentNavId !== prevProps.currentNavId){
			this.setState({
				showPortfolioThumbnails: false,
				showInstagramThumbnails: true,
			})
		}

		if(this.state.isDesktop !== prevState.isDesktop){
			this.setState({
				showInstagramThumbnails: this.state.isDesktop
			})
		}
	}

	updateDimensions = () => {
		const sizes = getViewPortSize();

		if(sizes){
			this.setState({
				isDesktop: sizes.width > 992,
			});
		}
	};

	closeClicked = () => {
		this.targetElement = this.targetRef.current;
		this.setState({
			visible: false,
		});

		setTimeout(() => {
			disableBodyScroll(this.targetElement);
			this.props.closeClicked();
		}, 100)
	};

	componentWillUnmount(): void {
		clearAllBodyScrollLocks();
		window.removeEventListener("resize", this.updateDimensions);
	}

	getGalleryTpl = (type: 'portfolio' | 'digitals' | 'instagram' | 'video') => {
		const { modelStore, instagramStore } = this.props;
		const { showPortfolioThumbnails, showDigitalsThumbnails, showInstagramThumbnails, isDesktop, media } = this.state;

		if (modelStore.isFetching || instagramStore.isFetching) {
			return <Loader/>
		}

		if(!modelStore.currentModel)
			return null;

		let tpl;

		switch (type) {
			case 'portfolio':
				tpl = (
					<Fragment>
						{
							modelStore.currentModel.pictures.length > 0 &&
								<ModelGallery
									album={'portfolio'}
									isDesktop={isDesktop}
									toggleThumbnails={() => this.setState({ showPortfolioThumbnails: false })}
									showThumbnails={showPortfolioThumbnails}
									id={1}
									media={media} />
						}
					</Fragment>
				);
				break;
			case 'digitals':
				tpl = (
					<Fragment>
						{
							modelStore.currentModel.digitals && modelStore.currentModel.digitals.length > 0 &&
								<ModelGallery
									album={'digitals'}
									isDesktop={isDesktop}
									mosaicAlignment={'center'}
									mosaicSize={'large'}
									single={true}
									toggleThumbnails={() => this.setState({ showDigitalsThumbnails: false })}
									showThumbnails={showDigitalsThumbnails}
									id={2}
									media={media} />
						}
					</Fragment>
				);
				break;
			case 'video':
				tpl = (
					<Fragment>
						{
							modelStore.currentModel.videos.length > 0 &&
							<ModelVideo
								id={4}
								isDesktop={isDesktop}
								videos={modelStore.currentModel.videos} />
						}
					</Fragment>
				);
				break;
			case 'instagram':
				tpl = (
					<Fragment>
						<ModelGallery
							album={'instagram'}
							media={media}
							isDesktop={isDesktop}
							mosaicAlignment={'center'}
							single={true}
							toggleThumbnails={() => this.toggleInstagramThumbnailsVisibility()}
							showThumbnails={isDesktop && showInstagramThumbnails}
							mosaicSize={'large'}
							id={3} />
					</Fragment>
				);
				break;
			default: tpl = null;
		}

		return tpl;
	};

	getPortfolioActionsTpl = () => {
		const { modelStore } = this.props;

		return (
			modelStore.currentModel && !constants.DISABLE_EXPORT_PDF ?
			<div className={classes['ModelLightBox-actions']}>
				<PDFPortfolioButton
					model={modelStore.currentModel}
					fileName={`${modelStore.currentModel.firstName.toLowerCase()}-${modelStore.currentModel.lastName.toLowerCase()}-pdf-book-${moment().format('YYYYMMDD')}`}
					label={<FormattedMessage id={'label.pdfBook'} />} />
			</div> : null
		)
	};

	togglePortfolioThumbnailsVisibility = () => {
		const showPortfolioThumbnails = !this.state.showPortfolioThumbnails;

		this.setState({
			showPortfolioThumbnails
		})
	};

	getDigitalActionsTpl = () => {
		const { modelStore, instagramStore } = this.props;

		return (
			modelStore.currentModel && !constants.DISABLE_EXPORT_PDF ?
				<div className={classes['ModelLightBox-actions']}>
					<PDFDigitalButton
						instagramUser={instagramStore.currentProfile ? instagramStore.currentProfile.username : ''}
						model={modelStore.currentModel}
						fileName={`${modelStore.currentModel.firstName.toLowerCase()}-${modelStore.currentModel.lastName.toLowerCase()}-digitals-${moment().format('YYYYMMDD')}`}
						label={<FormattedMessage id={'label.pdf'} />} />
				</div> : null
		)
	};

	toggleDigitalThumbnailsVisibility = () => {
		const showDigitalsThumbnails = !this.state.showDigitalsThumbnails;

		this.setState({
			showDigitalsThumbnails
		})
	};

	getInstagramActionsTpl = () => {
		const { instagramStore } = this.props;

		let instagramLink = instagramStore.currentProfile ?
			`https://www.instagram.com/${instagramStore.currentProfile.username}` : undefined;

		return (
			instagramLink ?
				<FormattedMessage id={'text.follow'} defaultMessage={''}>
					{
						(text: any) => (
							<div className={classes['ModelLightBox-actions']}>
								<a
									className={classes['ModelLightBox-instagram']}
									href={instagramLink}
									target={'_blank'}>
									{ instagramStore.currentProfile && text.replace('--username--', instagramStore.currentProfile.username)}
								</a>
							</div>
						)
					}
				</FormattedMessage> : null
		)
	};

	toggleInstagramThumbnailsVisibility = () => {
		const showInstagramThumbnails = !this.state.showInstagramThumbnails;

		this.setState({
			showInstagramThumbnails
		})
	};

	toggleThumbnailsVisibility = () => {
		const { currentNavId } = this.props;

		if(currentNavId === 1){
			this.togglePortfolioThumbnailsVisibility();
		}

		if(currentNavId === 2){
			this.toggleDigitalThumbnailsVisibility();
		}

		if(currentNavId === 3){
			this.toggleInstagramThumbnailsVisibility();
		}
	};

	render () {
		const { modelStore, navItems, currentNavId } = this.props;
		const { visible } = this.state;

		let contentTpl: object | null = null;
		let actionsTpl: object | null = null;

		switch (currentNavId) {
			case 1: contentTpl = this.getGalleryTpl('portfolio'); actionsTpl = this.getPortfolioActionsTpl();
				break;
			case 2: contentTpl = this.getGalleryTpl('digitals'); actionsTpl = this.getDigitalActionsTpl();
				break;
			case 3: contentTpl = this.getGalleryTpl('instagram'); actionsTpl = this.getInstagramActionsTpl();
				break;
			case 4: contentTpl = this.getGalleryTpl('video'); actionsTpl = null;
				break;
			default: contentTpl = null; actionsTpl = null;
		}

		return (
			<div
				ref={this.targetRef}
				className={[
					classes.ModelLightBox,
					visible ? classes['is-visible'] : ''
				].join(' ')}>
				<div className={classes['ModelLightBox-content']}>
					<div className={classes['ModelLightBox-header']}>
						<Button
							hideEffect={true}
							type={'link'}
							active={true}
							clicked={this.closeClicked}>
							<FormattedMessage id={'button.back'} />
						</Button>
						{
							currentNavId !== 4 &&
								<Button
									hideEffect={true}
									type={'link'}
									active={true}
									icon={{
										position: 'left',
										src: thumbnailsIcon
									}}
									clicked={() => this.toggleThumbnailsVisibility()}>
									<FormattedMessage id={'label.thumbnail'} />
								</Button>
						}
						<img src={triangle} alt={'Fabbrica Milano'} className={classes['ModelLightBox-logo']} />
					</div>
					<div className={[classes['ModelLightBox-media'], visible ? classes['is-visible'] : ''].join(' ')}>
						{
							contentTpl
						}
					</div>
					<div className={classes['ModelLightBox-footer']}>
						<ModelNavigation
							modelStore={modelStore}
							title={modelStore.currentModel ? modelStore.currentModel.fullName : ''}
							theme={'lightBox'}
							showDigitals={modelStore.currentModel && modelStore.currentModel.digitals && modelStore.currentModel.digitals.length > 0}
							showVideos={modelStore.currentModel && modelStore.currentModel.videos && modelStore.currentModel.videos.length > 0}
							items={navItems} />
						{
							actionsTpl
						}
						<div className={classes['ModelLightBox-footerLogo']}>
							<img src={triangle} alt={'Fabbrica Milano'} className={classes['ModelLightBox-triangle']} />
							<a href={constants.INSTAGRAM.link} target={'_blank'} className={classes['ModelLightBox-instagram']}>
								{ `@${constants.INSTAGRAM.username}` }
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ModelLightBox;
