import React, { Component } from 'react';
import { ICover } from '../../shared/interfaces';
import Cover from '../Cover';
import CoverLogo from '../CoverLogo';
import classes from './styles.module.scss';
import { COVERS } from '../../shared/data';

import { Zoom } from 'react-slideshow-image';

export default class CoverGallery extends Component<{}, { duration: number, counter: number }> {
  state = {
    duration: 3000,
    counter: 1,
  };

  getSlideShowProperties = (duration: number) =>  {
    return {
      duration,
      transitionDuration: 0,
      infinite: true,
      indicators: false,
      arrows: false,
      scale: 1.4
    };
  };

  getSlider = () => {
    return COVERS.map(
      (cover: ICover, index: number) =>
      <Cover
        key={index}
        background={cover.background}
        triangle={cover.triangle} />)
  };

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{ duration: number; counter: number }>, snapshot?: any): void {
    /*
    if(this.state.counter !== prevState.counter){
      if(this.state.duration < 3000){
        if(this.state.counter > 32 && this.state.counter <= 64){
          this.setState({
            duration: 500
          })
        }
        if(this.state.counter > 64 && this.state.counter <= 96){
          this.setState({
            duration: 1000
          })
        }
        if(this.state.counter > 96){
          this.setState({
            duration: 3000
          })
        }
      }
    }
     */
  }

  onSlideChanged = () => {
    let counter = this.state.counter + 1;

    this.setState({
      counter
    });
  };

  render(){
    const { duration } = this.state;

    return (
      <div className={classes.CoverGallery}>
        <div className={classes['CoverGallery-logo']}>
          <CoverLogo />
        </div>
        <div className={classes['CoverGallery-content']}>
          <Zoom
            onChange={this.onSlideChanged}
            {...this.getSlideShowProperties(duration)}>
            { this.getSlider() }
          </Zoom>
        </div>
      </div>
    )
  }
}
