import React from 'react';
import classes from './styles.module.scss';

import triangle from '../../assets/images/triangles/animated-triangles--black.gif';

interface IProps {
	minHeight?: string;
	size?: 'large';
	alignment?: 'center';
}

const loader = ({ minHeight, size, alignment }: IProps) => {

	const styles = {
		minHeight: minHeight ? minHeight : '20rem'
	};

	return (
		<div
			className={[
				classes.Loader,
				size ? classes[`Loader--${size}`] : '',
				alignment ? classes[`Loader--${alignment}`] : ''
			].join(' ')}
			style={styles}>
			<img className={classes['Loader-image']} src={triangle} alt={'Loading...'} />
		</div>
	);
};

export default loader;
