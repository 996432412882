import React, { Component } from 'react';
import classes from './styles.module.scss';
import Cleave from 'cleave.js/react';

interface IProps {
	placeholder: string;
	value: string;
	changed: (value: string) => void;
}

interface IState {
	placeholder: string;
}

class DatePicker extends Component<IProps, IState> {
	state = {
		placeholder: this.props.placeholder || ''
	};

	onCreditCardChange = (event) => {
		this.props.changed(event.target.value);
	};

	onCreditCardFocus = () => {
		this.setState({
			placeholder: 'DD-MM-YYYY'
		})
	};

	onCreditCardBlur = () => {
		this.setState({
			placeholder: this.props.placeholder || ''
		})
	};

	render(){
		const { placeholder } = this.state;

		return (
			<div className={classes.DatePicker}>
				<Cleave
					placeholder={placeholder}
					options={{
						date: true,
						delimiter: '-',
						datePattern: ['d', 'm', 'Y']
					}}
					onFocus={this.onCreditCardFocus}
					onBlur={this.onCreditCardBlur}
					onChange={this.onCreditCardChange} />
			</div>
		)
	}
}

export default DatePicker;
