import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { getViewPortSize } from '../../shared/helpers';
import { CONTACTS } from '../../shared/data';
import { IContact } from '../../shared/interfaces';
import classes from './styles.module.scss';
import constants from '../../shared/constants';

interface IState {
	minHeight: string;
}

class ContactsList extends Component<{}, IState> {
	state = {
		minHeight: '20rem'
	};

	componentDidMount(): void {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		const sizes = getViewPortSize();

		if(sizes){
			this.setState({
				minHeight: `calc(${sizes.height}px - 21rem)`
			});
		}
	};

	componentWillUnmount(): void {
		window.removeEventListener("resize", this.updateDimensions);
	}

	render () {
		const contactsTpl = CONTACTS.map((contact: IContact, index: number) => {
			return (
				<li key={index} className={classes['ContactsList-item']}>
					<label className={classes['ContactsList-label']}>
						{ contact.label }
					</label>
					<div className={classes['ContactsList-values']} dangerouslySetInnerHTML={{__html: contact.values.join('<br>')}} />
				</li>
			)
		});

		return (
			<div className={classes.ContactsList} style={{minHeight: this.state.minHeight}}>
				<ul className={classes['ContactsList-items']}>
					{ contactsTpl }
				</ul>
				<div className={classes['ContactsList-wrapper']}>
					<div className={classes['ContactsList-values']}>
						<a href={constants.PRIVACY_POLICY_LINK} target={'_blank'}>
							<FormattedHTMLMessage id={"privacyPolicy"} />
						</a>
					</div>
					<div className={classes['ContactsList-values']}>
						<a href={constants.COOKIE_POLICY_LINK} target={'_blank'}>
							<FormattedHTMLMessage id={"cookiePolicy"} />
						</a>
					</div>
					<div className={classes['ContactsList-values']}>
						<a href={constants.TERMS_AND_CONDITIONS_POLICY_LINK} target={'_blank'}>
							<FormattedHTMLMessage id={"termsAndConditions"} />
						</a>
					</div>
				</div>
				<div className={classes['ContactsList-wrapper']}>
					<div className={classes['ContactsList-values']}>
						<a href={'https://pop-eye.studio/'} target={'_blank'}>
							<FormattedHTMLMessage id={"credits.design"} />
						</a>
					</div>
					{/* <div className={classes['ContactsList-values']}>
						<a href={'https://www.vas.it/'} target={'_blank'}>
							<FormattedHTMLMessage id={"credits.development"} />
						</a>
					</div> */}
				</div>
			</div>
		);
	}
}

export default ContactsList;
