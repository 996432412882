import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import classes from './styles.module.scss';
import * as routes from '../../shared/routes';

interface IProps {
	id: number;
	image: string;
	content: string;
}

@inject('modelStore')
@observer
class WomanCard extends Component<IProps> {
	render () {
		const { id, image, content } = this.props;
		const backgroundImage = {
			backgroundImage: this.props.image ? `url(${image})` : 'none'
		};
		const slug = content.toLowerCase().replace(/ /g, "-") + "-" + id

		return (
			<NavLink
				to={routes.MODEL.replace(':id', slug)}
				className={classes.WomanCard}>
				<div className={classes['WomanCard-wrapper']}>
					<div className={classes['WomanCard-image']} style={backgroundImage} />
				</div>
				<div className={classes['WomanCard-content']}>
					{
						content
					}
				</div>
			</NavLink>
		);
	}
}

export default WomanCard;
