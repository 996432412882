import React, { Component } from 'react';
import constants from '../../shared/constants';
import Navigation from '../Navigation';
import classes from './styles.module.scss';

import triangle from '../../assets/images/triangles/animated-triangles--black.gif';

export default class Footer extends Component {
	render(){

		return (
			<footer className={classes.Footer}>
				<div className={classes['Footer-content']}>
					<div className={classes['Footer-navigation']}>
          	<Navigation />
					</div>
          <img src={triangle} alt={'Fabbrica Milano'} className={classes['Footer-logo']} />
					<a href={constants.INSTAGRAM.link} target={'_blank'} className={classes['Footer-instagram']}>
						{ `@${constants.INSTAGRAM.username}` }
					</a>
				</div>
			</footer>
		)
	}
}
