import React from 'react';
import classes from './styles.module.scss';
import darkLogo from '../../assets/images/logo-dark.svg';
import lightLogo from '../../assets/images/logo-light.svg';
import fabbricaLogo from '../../assets/images/fabbrica-milano-cover.svg';

interface IProps {
  light?: boolean;
  extended?: boolean;
}

export default (props: IProps) => {
  let logo = props.light ? lightLogo : darkLogo;

  if(props.extended){
    logo = fabbricaLogo;
  }

  return (
    <img
      src={logo}
      className={classes.Logo}
      alt={'Fabbrica Milano'} />
  )
}
