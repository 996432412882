import React from 'react';
import classes from './styles.module.scss';

interface titleProps {
	children?: any;
	styles?: object;
	size?: string;
	uppercase?: boolean;
	alignment?: 'center' | 'right' | 'left';
}

const title = ({ children, styles, size, uppercase, alignment }: titleProps) => {
	const titleClass = [
		classes.Title,
		size ? classes[`Title--${size}`] : '',
		uppercase ? classes[`Title--uppercase`] : '',
		alignment ? classes[`Title--${alignment}`] : ''
	].join(' ');

	return <h1 className={titleClass} style={styles}>{children}</h1>;
};

export default title;
