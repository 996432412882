import React, { Component } from 'react';
import classes from './styles.module.scss';
import { getViewPortSize } from '../../shared/helpers';

interface IProps {
	background: string;
	triangle: string;
}
export default class Cover extends Component<IProps> {
  state = {
    coverHeight: '39.375rem'
  };

  componentDidMount(): void {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const sizes = getViewPortSize();

    if(sizes){
      this.setState({
        coverHeight: `calc(${sizes.height}px - 10rem)`
      });
    }
  };

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render(){
		const { background, triangle } = this.props;
		const { coverHeight } = this.state;

		return (
			<div
				className={classes.Cover}>
				<div className={classes['Cover-overlay']} />
				<div
          className={classes['Cover-content']}
          style={{
            backgroundImage: `url(${background})`,
            height: coverHeight
          }}>
					<img className={classes['Cover-triangle']} src={triangle} alt={'fabbrica'} />
				</div>
			</div>
		)
	}
}
