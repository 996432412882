import {
	clearAllBodyScrollLocks,
	disableBodyScroll,
	enableBodyScroll,
} from 'body-scroll-lock';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import constants from '../../shared/constants';
import { NAVIGATION } from '../../shared/data';
import { INav } from '../../shared/interfaces';
import Button from '../Button';
import classes from './styles.module.scss';

import triangle from '../../assets/images/triangles/animated-triangles--black.gif';

interface IProps {
	show: boolean;
	closeClicked: () => void;
}

interface IState {
	visible: boolean;
}

class OverlayNav extends Component<IProps, IState> {
	targetRef = React.createRef<HTMLDivElement>();
	targetElement;

	state = {
		visible: false,
	};

	componentWillUnmount() {
		clearAllBodyScrollLocks();
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if(this.props.show !== prevProps.show){
			if(this.props.show){
				if(this.targetRef){
					this.targetElement = this.targetRef.current;

					disableBodyScroll(this.targetElement);

					setTimeout(() => {
						this.setState({
							visible: true,
						})
					}, 200)
				}
			} else {
				if(this.targetRef){
					this.targetElement = this.targetRef.current;

					enableBodyScroll(this.targetElement);

					setTimeout(() => {
						this.setState({
							visible: false,
						}, () => {
							this.props.closeClicked()
						})
					}, 200)
				}
			}
		}
	}

	render () {
		const { closeClicked } = this.props;
		const { visible } = this.state;

		const navTpl = NAVIGATION.filter((nav: INav) => nav.key !== 'home' && nav.visible === true).map((nav: INav, index: number) => {
			const navLink = nav.key !== 'women' ?
				(nav.key !== 'shop' ?
				<NavLink
					className={classes['OverlayNav-link']}
					to={nav.route}>
					{ nav.label }
				</NavLink> :
				<a
					href={nav.route}
					target="_blank"
					className={classes['OverlayNav-link']}
				>
					{ nav.label }
				</a>
				) :
				<div
					className={[
						classes['OverlayNav-wrapper']
					].join(' ')}>
						<span className={classes['OverlayNav-link']}>{ nav.label }</span>
						<div className={classes['OverlayNav-submenu']}>
							<Button
								type={'link'}
								redirectTo={nav.route.replace(':group', 'image')}>
								<FormattedMessage id={'woman.filter.image'} />
							</Button>
							<Button
								type={'link'}
								redirectTo={nav.route.replace(':group', 'management')}>
								<FormattedMessage id={'woman.filter.management'} />
							</Button>
							<Button
								type={'link'}
								redirectTo={nav.route.replace(':group', 'development')} >
								<FormattedMessage id={'woman.filter.development'} />
							</Button>
							<Button
								type={'link'}
								redirectTo={nav.route.replace(':group', 'main')}>
								<FormattedMessage id={'woman.filter.main'} />
							</Button>
						</div>
				</div>;


			return (
				<li
					className={[
						classes['OverlayNav-item'],
						nav.key !== 'woman' ? '' : classes['OverlayNav-item--hasSubmenu']
					].join(' ')}
					key={index}>
					{ navLink }
				</li>
			)
		});

		return (
			<div
				ref={this.targetRef}
				className={[
					classes.OverlayNav,
					visible ? classes['is-visible'] : ''
				].join(' ')}>
				{
					/*

				<button
					onClick={closeClicked}
					className={[
						classes['OverlayNav-toggle'],
						show ? classes['is-active'] : ''].join(' ')}>
					<span />
					<span />
					<span />
				</button>
					 */
				}
				<div className={[classes['OverlayNav-content'], visible ? classes['is-visible'] : ''].join(' ')}>
					<div className={[classes['OverlayNav-close'], visible ? classes['is-active'] : ''].join(' ')}>
						<Button
							type={'link'}
							active={true}
							clicked={closeClicked}>
							<FormattedMessage id={'button.close'} />
						</Button>
					</div>
					<nav className={classes['OverlayNav-menu']}>
						<ul>
							{
								navTpl
							}
						</ul>
					</nav>
					<div className={classes['OverlayNav-footer']}>
						<img src={triangle} alt={'Fabbrica Milano'} className={classes['OverlayNav-logo']} />
						<a href={constants.INSTAGRAM.link} target={'_blank'} className={classes['OverlayNav-instagram']}>
							{ `@${constants.INSTAGRAM.username}` }
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default OverlayNav;
