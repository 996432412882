import React, {Component, createRef} from 'react';
import { IVideo } from '../../shared/interfaces';
import Loader from '../Loader';
import Video from '../Video';
import classes from './styles.module.scss';
import arrowBackIcon from '../../assets/icons/arrow-back.svg';
import arrowNextIcon from '../../assets/icons/arrow-next.svg';
import AliceCarousel from "react-alice-carousel";

const responsive = {
	0: { items: 1 },
  //1280: { items: 2 },
};

interface IProps {
  id: number;
  videos: Array<IVideo>;
  isDesktop: boolean;
}

interface IState {}

export default class ModelVideo extends Component<IProps, IState> {
	modelVideosCarousel = createRef<AliceCarousel>();

	state = {
	  loading: true,
  };

  componentDidMount(): void {
    setTimeout(() => {
    	this.setState({
				loading: false
			}, () => {
				document.addEventListener('keydown', this.addKeyEvents);
			})
		}, 2200);
  }

  componentWillUnmount(): void {
		window.removeEventListener('keydown', this.addKeyEvents);
  }

  addKeyEvents = (event: KeyboardEvent) => {
		if (event.code === 'ArrowLeft') {
			this.goToPreviousSlide()
		}
		if (event.code === 'ArrowRight') {
			this.goToNextSlide()
		}
	};

  goToNextSlide = () => {
    if(this.modelVideosCarousel.current){
      this.modelVideosCarousel.current.slideNext();
    }
  };

  goToPreviousSlide = () => {
    if(this.modelVideosCarousel.current){
      this.modelVideosCarousel.current.slidePrev();
    }
  };

  render(){
    const { videos, isDesktop } = this.props;
    const { loading } = this.state;

    return (
      <div className={classes.ModelVideo}>
        {
          !loading && videos.length > 1 &&
            <div className={classes['ModelVideo-navigation']}>
              <button
                className={classes['ModelVideo-button']}
                onClick={this.goToPreviousSlide}>
                <img src={arrowBackIcon} alt={'back'} />
              </button>
              <button
                className={[classes['ModelVideo-button'], classes['ModelVideo-button--next']].join(' ')}
                onClick={this.goToNextSlide}>
                <img src={arrowNextIcon} alt={'next'} />
              </button>
            </div>
        }
				{
					loading && <Loader alignment={'center'} size={'large'} />
				}
        <div className={[
          classes['ModelVideo-content'],
          !loading && classes['is-visible']
        ].join(' ')}>
          <AliceCarousel
            swipeDisabled={isDesktop}
            ref={this.modelVideosCarousel}
            autoPlay={false}
            buttonsDisabled={true}
            dotsDisabled={true}
            responsive={responsive}>
            {
              videos.map((video: IVideo, index: number) => {
                return (
                  <Video key={index} url={video.url} />
                )
              })
            }
          </AliceCarousel>
        </div>
      </div>
    )
  }
}
