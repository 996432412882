import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import constants from '../../shared/constants';
import Button from '../Button';
import classes from './styles.module.scss';

interface IProps {
	filterClicked: (gr: number) => void;
	currentGroup: number;
}

class WomanFilters extends Component<IProps> {
	render () {
		const { filterClicked, currentGroup } = this.props;

		return (
			<div className={classes.WomanFilters}>
				<Button
					type={'link'}
					active={currentGroup === constants.WOMEN_GROUPS[0].id}
					clicked={() => filterClicked(constants.WOMEN_GROUPS[0].id)}>
					<FormattedMessage id={'woman.filter.image'} />
				</Button>
				<Button
					type={'link'}
					active={currentGroup === constants.WOMEN_GROUPS[1].id}
					clicked={() => filterClicked(constants.WOMEN_GROUPS[1].id)}>
					<FormattedMessage id={'woman.filter.management'} />
				</Button>
				<Button
					type={'link'}
					active={currentGroup === constants.WOMEN_GROUPS[2].id}
					clicked={() => filterClicked(constants.WOMEN_GROUPS[2].id)}>
					<FormattedMessage id={'woman.filter.development'} />
				</Button>
				<Button
					type={'link'}
					active={currentGroup === constants.WOMEN_GROUPS[3].id}
					clicked={() => filterClicked(constants.WOMEN_GROUPS[3].id)}>
					<FormattedMessage id={'woman.filter.main'} />
				</Button>
			</div>
		);
	}
}

export default WomanFilters;
