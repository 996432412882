import React from 'react';
import classes from './styles.module.scss';

interface IProps {
	children?: any;
	styles?: object;
	size?: string;
	uppercase?: boolean;
	alignment?: 'center' | 'right' | 'left';
	light?: boolean;
}

const label = ({ children, styles, size, light }: IProps) => {
	const labelClass = [
		classes.Label,
		size ? classes[`Label--${size}`] : '',
		light ? classes[`Label--light`] : '',
	].join(' ');

	return <label className={labelClass} style={styles}>{children}</label>;
};

export default label;
