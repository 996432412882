import React from 'react';
import { ISelectItem } from '../../../shared/interfaces';
import classes from './SelectItem.module.scss';

interface IProps {
  item: ISelectItem
	itemClicked: any;
}

const selectItem = (props: IProps) => {

  const { item, itemClicked } = props;

  return (
    <button
      tabIndex={-1}
      className={classes.SelectItem}
      onClick={() => itemClicked(item)}>
      {
        item.label
      }
    </button>
  );

};

export default selectItem;
