import { History } from 'history';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import Ticker from 'react-ticker';
import { IModelsTicker, IModelStore } from '../../shared/interfaces';
import classes from './styles.module.scss';

interface IProps {
	modelStore: IModelStore;
	history: History;
}

interface IState {
	move: boolean;
	speed: number;
}

@inject('modelStore')
@observer
class List extends Component<IProps, IState> {
	state = {
		move: true,
		speed: 0,
	};

	componentDidMount(): void {
		const { modelStore: { fetchMainModels } } = this.props;

		fetchMainModels();

		setTimeout(() => {
			this.setState({
				speed: 2,
			})
		}, 1500)
	}

	handleMove = (move) => {
		this.setState({
			move
		})
	};

	render () {
		const { move, speed } = this.state;
		const { modelStore: { mainModels } } = this.props;

		const modelsTpl = mainModels && mainModels.map((model: IModelsTicker, index: number) => {
			return (
				<a
					onMouseOver={() => this.handleMove(false)}
					onMouseLeave={() => this.handleMove(true)}
					key={index}
					className={classes['List-item']}
					href={model.url} target={'_blank'}>
					<strong>{ model.name }</strong>
					<span>x</span>
					<span>{ model.brand }</span>
				</a>
			)
		});

		return (
			mainModels && mainModels.length > 0 &&
				<Ticker move={move} speed={speed}>
					{
						() =>
							<div
								className={classes.List}>
								{ modelsTpl }
							</div>
					}
				</Ticker>
		);
	}
}

export default List;
